import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { YAPILY } from '../constants';

const bankHandler = {
  /**
   *
   * @param {Array} institutions
   * @returns {Array}
   */
  getCountries(institutions) {
    if (!institutions) {
      return [];
    }
    const allCountries = institutions.flatMap(
      (institution) => institution.countries,
    );
    const countryMap = new Map();
    allCountries.forEach((country) =>
      countryMap.set(country.country_code_2, {
        id: country.country_code_2,
        name: country.display_name,
        flag: getUnicodeFlagIcon(country.country_code_2),
      }),
    );
    const countries = Array.from(countryMap.values());
    countries.sort((a, b) => a.name.localeCompare(b.name));
    return countries;
  },
  getConsentEndDate({ country, locale }) {
    let datum = new Date();
    const days = country === YAPILY.COUNTRY.GB ? 90 : 180;
    datum.setDate(datum.getDate() + days);
    return datum.toLocaleDateString(locale);
  },
  /**
   *
   * @param {Array} institutions
   * @param {String} countryId
   * @returns {Array}
   */
  filterByCountryId(institutions, countryId) {
    if (!institutions) {
      return [];
    }
    return institutions.filter((institution) =>
      institution.countries.find(
        (country) => country.country_code_2 === countryId,
      ),
    );
  },
};

export default bankHandler;

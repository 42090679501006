import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const EidView = loadView('eid/EidView');

export const eidRoute = {
  path: ROUTES.PATH.EID,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.EID,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.EID,
      component: EidView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
  ],
};

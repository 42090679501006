import { info } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initApp({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchInfo')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchInfo({ commit }) {
    try {
      const response = await info.requestInfo();
      commit('setInfo', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

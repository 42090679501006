export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  services: (state) => state.services,
  endpoints: (state) => {
    const chains = state.services?.chains;
    if (!chains || !chains.length) {
      return [];
    }
    const chain = chains.find((chain) => chain.tag === state.services.default);
    return chain.endpoints;
  },
};

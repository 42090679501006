<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="primary--text">
          <span v-text="title" />
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="dialog = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="px-5 text-center">
        <div class="text-subtitle-2" v-html="description" />
        <qrcode-vue :value="seed" size="200" level="H" class="py-3" />
        <v-divider />
        <div class="pt-1 text-caption font-italic" v-text="caption" />
        <div class="font-weight-bold font-monotype" v-text="seed" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import QrcodeVue from 'qrcode.vue';

import { mdiClose } from '@mdi/js';

export default {
  name: 'BaseModalQRCode',
  components: {
    QrcodeVue,
  },
  props: {
    value: Boolean,
    seed: {
      type: String,
      required: true,
      note: 'The value to make the qr code',
    },
    title: {
      type: String,
      required: true,
      note: 'The modal title',
    },
    caption: {
      type: String,
      default: '',
      note: 'The modal caption',
    },
    description: {
      type: String,
      default: '',
      note: 'The modal description',
    },
  },
  emits: ['input'],
  data() {
    return {
      mdiClose,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestCredentials(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: APIS.EKYC,
      method: 'get',
      headers,
    });
  },
};

export const msgs = {
  acceptAdditional: 'I have read and specifically accept the Additional Terms.',
  acceptError: 'To continue you must accept the contract',
  acceptNodeAdditional: 'I acknowledge that I have read and specifically accept the Additional Terms of the Distributor Node Agreement',
  acceptNodeTos: 'I acknowledge that I have read and accept the terms of the Distributor Node Agreement',
  acceptTos: 'I acknowledge that I have read the Agreement for the purchase of technology goods and services ("Agreement") General Terms and Conditions and accept the terms.',
  addressError: 'Incorrect address',
  alphaNumField: 'This field accepts only alphanumeric characters',
  amountError: 'This is not a valid amount',
  apiEmptyList: 'No API found',
  cashBackBonus: 'cash back in credits',
  codeLengthError: 'Min 5 and max 16 characters',
  configuringData: 'Please wait, we are configuring your data',
  configuringError: 'An error occurred during configuration, contact the administrator',
  copied: 'Copied',
  credentialsEmptyList: 'No credential found',
  creditsBuyFooter: 'Warning: all Credits/token transaction are final and cannot be reversed',
  creditsSendFooter: 'Warning: all Credits/token transaction are final and cannot be reversed',
  description: 'Description',
  docsLoseAssociation: 'Number of documents that will remain without association:',
  earnShare: 'Give $25 to all companies by sharing this link and you will receive $25 too in free credits when they will purchase a subscription.',
  emailField: 'This field accepts only valid email addresses',
  errorOccurred: 'Sorry, but an error occurred',
  fiscalCodeError: 'Incorrect fiscal code',
  mandatoryField: 'This field is mandatory',
  membershipBenefits:'The service consists of a one-off free variable allocation of Trade Cash Credits CCC for promotional purposes that can be used for the sole purpose of testing the services.',
  membershipBenefitsIntro: 'Access to this experimental service is granted for a temporary period and can be removed at any time. At this time, COMMERC.IO offers the following Extra Benefits:',
  modalQrCodeDesc: 'This is you public Identity, share it with anyone you want to do business',
  modalQrWalletDesc: 'This is your Wallet , share it with anyone you want to get paid',
  name: 'Name',
  noCode: 'No code',
  noMessage: 'No message',
  notAvailable: 'Not available',
  notFound: 'Not found',
  operationDeleted: 'Operation deleted',
  pageNotFound: 'Page not found',
  paidFeature: 'This feature is active only with Bronze membership or higher',
  paymentCheck: 'Please wait, we are verifying the payment and configuring your data',
  paymentDeleted: 'Payment has been stopped',
  paymentError: 'Process failed, contact administrator',
  purchaseAuthAccount: 'Account authenticated, you are about to be directed to obtain payment authorization',
  purchaseAuthPayment: 'Payment authorized, to complete the purchase you must confirm it',
  purchaseMembership: 'Use it, invite your contacts and earn a Monthly Income',
  purchaseTokenCost: 'Purchase token cost',
  sdiLengthError: 'The SDI code is a 7-digit alphanumeric sequence',
  sendingConfirm: 'Do you confirm the sending?',
  sendingTokens: 'Please wait, we are sending the purchased CCCs to your wallet',
  sendingTokensCompleted: 'Sending of CCC completed successfully',
  sendingTokensFailed: 'Sending of CCC failed, please print the summary and contact administrator',
  serviceTemporarilyUnavailable: "Service temporarily unavailable",
  startTypingToFilterCountries: 'Start typing to filter countries',
  startTypingToSearch: 'Start typing to search',
  textLengthError: 'Min 3 and max 256 characters',
  triggeredAnError: 'Something happened in setting up the request that triggered an error',
  txsEmptyList: 'No transaction found',
  unansweredRequest: 'The request was made but no response was received',
  vatError: 'Incorrect VAT number',
  vatLengthError: 'Min 5 and max 15 characters',
};

import { faker } from '@faker-js/faker';

const mockConsent = (paragraphs = 3) => {
  const item = faker.lorem.paragraphs(paragraphs);
  return item;
};

const mockInstitution = () => {
  const item = {
    countries: [
      {
        country_code_2: 'GB',
        display_name: 'United Kingdom',
      },
    ],
    credentials_type: 'OPEN_BANKING_UK_AUTO',
    environment_type: 'SANDBOX',
    full_name: 'Modelo Sandbox',
    id: 'modelo-sandbox',
    media: [
      {
        source:
          'https://images.yapily.com/image/ca502f24-d6df-4785-b4b8-1034b100af77?size=0',
        type: 'logo',
      },
    ],
    name: 'Modelo Sandbox',
  };
  return item;
};

const mockInstitutions = (institutions = 5) => {
  const items = new Array(institutions).fill(null).map(() => mockInstitution());
  return items;
};

export { mockConsent, mockInstitution, mockInstitutions };

import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @returns {AxiosPromise}
   */
  requestServices() {
    return axios({
      url: APIS.SERVICE_DISCOVERY,
      method: 'get',
    });
  },
};

import { serviceDiscovery } from '@/apis';

export default {
  /**
   *
   * @param {Function} dispatch
   */
  async initApiDashboard({ dispatch }) {
    const requests = [
      dispatch('fetchApiDashboardData'),
      dispatch('header/initHeader', null, { root: true }),
    ];
    await Promise.all(requests);
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async fetchApiDashboardData({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('getServices')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async getServices({ commit }) {
    commit('setServices', null);
    try {
      const response = await serviceDiscovery.requestServices();
      commit('setServices', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

const COUNTRY = Object.freeze({
  GB: 'GB',
  IT: 'IT',
});

const TERMS = ({ bank, country, date }) => {
  switch (country) {
    case COUNTRY.IT:
      return `<p>Abbiamo stretto una partnership con Yapily Connect per accedere ai vostri dati bancari presso ${bank}.</p><p>Ora sarete reindirizzati in modo sicuro a ${bank} per accedere alle seguenti informazioni:</p><ul><li>Dettagli account</li><li>Bilanci</li></ul><br><p>Utilizzando il servizio, l'utente accetta che Yapily Connect acceda ai suoi dati bancari, <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Terms & Conditions</a> e <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Privacy Notice</a>.</p><p>Il presente consenso sarà valido fino alla data ${date}</p>`;
    default:
      return `<p>We have partnered with Yapily Connect to access your bank data at ${bank}.</p><p>You will now be securely redirected to ${bank} to give access to the following information:</p><ul><li>Account(s) details</li><li>Balances</li></ul><br><p>By using the service, you agree to Yapily Connect accessing your bank data, the <a href="https://www.yapily.com/legal/end-user-terms" target="_blank">Terms & Conditions</a> and <a href="https://www.yapily.com/legal/privacy-policy" target="_blank">Privacy Notice</a>.</p><p>This consent will be valid until ${date}</p>`;
  }
};

export default Object.freeze({ COUNTRY, TERMS });

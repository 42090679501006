const validators = {
  isAddressValid(value) {
    const regEx = new RegExp('did:com:' + '[a-z0-9]{39}$', 'igm');
    return regEx.test(value.toLowerCase());
  },
  isFiscalCodeValid(value) {
    const regEx = new RegExp(
      /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i,
      'igm',
    );
    return regEx.test(value);
  },
  isVatValid(value) {
    const regEx = new RegExp(/^[0-9]{11}$/, 'igm');
    return regEx.test(value);
  },
  /**
   *
   * @param {Array.<String>} testValues
   * @param {Array.<String>} allowedValues
   * @returns {Boolean}
   */
  isArrayContentValid(testValues, allowedValues) {
    return testValues.every((value) => allowedValues.includes(value));
  },
};

export default validators;

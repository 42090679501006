const STABLE = Object.freeze({
  NAME: 'commercio cash credits',
  SYMBOL: 'CCC',
  DENOM: 'uccc',
  EXPONENT: 6,
  VALUE: {
    AMOUNT: '1.00',
    SYMBOL: '€',
  },
});

const TOKEN = Object.freeze({
  NAME: 'commercio',
  SYMBOL: 'COM',
  DENOM: 'ucommercio',
  EXPONENT: 6,
  STAKEABLE: true,
});

export default Object.freeze({
  STABLE,
  TOKEN,
});

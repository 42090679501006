<template>
  <v-card flat>
    <v-card-text>
      <v-form @submit.prevent="onSearch">
        <v-text-field
          dense
          outlined
          rounded
          v-model.trim="query"
          :placeholder="$t('labels.search')"
          :prepend-inner-icon="mdiMagnify"
        >
          <template #prepend>
            <the-search-bar-filter :sorts="sorts" :on-sorting="onSorting" />
          </template>
          <template #append-outer>
            <the-search-bar-eye
              :positions="positions"
              :on-positioning="onPositioning"
            />
          </template>
        </v-text-field>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import TheSearchBarEye from './TheSearchBarEye';
import TheSearchBarFilter from './TheSearchBarFilter';

import { mdiMagnify } from '@mdi/js';

export default {
  name: 'TheSearchBar',
  components: {
    TheSearchBarEye,
    TheSearchBarFilter,
  },
  props: {
    positions: Array,
    sorts: Array,
  },
  emits: ['positioning-view', 'search-query', 'sorting-list'],
  data() {
    return {
      mdiMagnify,
      query: '',
    };
  },
  methods: {
    onPositioning(value) {
      this.$emit('positioning-view', value);
    },
    onSearch() {
      this.$emit('search-query', this.query);
    },
    onSorting(value) {
      this.$emit('sorting-list', value);
    },
  },
};
</script>

<template>
  <the-error-message v-if="error" :error="error" data-test="error" />
  <v-row v-else data-test="content">
    <v-col cols="12" :sm="sm" :lg="lg">
      <v-card flat :loading="isLoading">
        <the-balance-box :coins="coins" token="uccc" />
      </v-card>
    </v-col>
    <v-col cols="12" :sm="sm" :lg="lg">
      <v-card flat>
        <base-dashboard-card title="CCC Price" info="0€ +0%">
          <template #content>
            <span class="text-h3 font-weight-bold" v-text="'€1'" />
          </template>
        </base-dashboard-card>
      </v-card>
    </v-col>
    <v-col cols="12" :sm="sm" :lg="lg">
      <v-card flat>
        <base-dashboard-card title="Unit process cost" info="ccc">
          <template #content>
            <span class="text-h3 font-weight-bold" v-text="unitProcessCost" />
          </template>
        </base-dashboard-card>
      </v-card>
    </v-col>
    <v-col v-if="hasTxs" cols="12" :sm="sm" :lg="lg" data-test="total-txs">
      <v-card flat :loading="isLoading">
        <base-dashboard-card title="Total Transactions" info="&nbsp;">
          <template #content>
            <span class="text-h3 font-weight-bold" v-text="totalTxs" />
          </template>
        </base-dashboard-card>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { COIN, SETTINGS } from '@/constants';
import { tokensHandler } from '@/utils';

export default {
  name: 'TheDashboardHeaderCommons',
  props: ['hasTxs'],
  computed: {
    ...mapGetters('account', ['subscription', 'wallet']),
    ...mapGetters('header', ['error', 'isLoading', 'processSummary']),
    lg() {
      return this.hasTxs ? 3 : 4;
    },
    sm() {
      return this.hasTxs ? 6 : 12;
    },
    coins() {
      return this.wallet && this.wallet.coins ? this.wallet.coins : [];
    },
    totalTxs() {
      return !this.processSummary
        ? '-'
        : this.processSummary.total > 999999
        ? `${this.processSummary.total / 1000} M`
        : this.processSummary.total;
    },
    unitProcessCost() {
      if (this.subscription) {
        const fee =
          SETTINGS.CHAIN_COST + parseInt(this.subscription.platform_fee.amount);
        return tokensHandler.convertFromBase(fee, COIN.STABLE.EXPONENT);
      }
      return '-.--';
    },
  },
};
</script>

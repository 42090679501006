export default Object.freeze({
  TITLE: `
  <h2>ACCORDO NODO DISTRIBUTORE (di seguito &ldquo;Accordo&rdquo;)</h2>
  <p>
    Termini e condizioni generali<br />
    Versione nr. 1 /data pubblicazione 15 Aprile 2024
  </p>
  `,
  P1: `
  <h3>1. Ambito di applicazione</h3>
  <p>
    Le seguenti condizioni generali regolano il rapporto tra COMMERCIO.NETWORK
    SpA (di seguito “COMMERCIO”), con sede legale in 36015- Schio (VI), Via
    Luigi dalla Via n. 3/b, cf/piva 04136930247, in persona del suo legale
    rappresentante pro tempore, da un lato, e il soggetto che vi aderisce (di
    seguito “DISTRIBUTORE”) dall’altro, avente ad oggetto l’attività di
    promozione della vendita dei prodotti e dei servizi di COMMERCIO come di
    seguito definiti.<br />
    Fanno parte integrante dell’Accordo anche i termini, le condizioni e le
    previsioni contenute nei link ipertestuali riportati nelle seguenti
    condizioni generali e nella versione vigente al compimento di ogni singola
    attività del DISTRIBUTORE.<br />
    Questo Accordo presuppone che il DISTRIBUTORE abbia sottoscritto su
    https://Commercio.app l’accordo quale USER e il contratto di Membership e
    sia titolare del relativo Account (come di seguito definito).<br />
    Questo Accordo costituisce l’intero accordo tra le Parti avente il
    medesimo oggetto e supera ogni altro precedente avente il medesimo oggetto
    e supera, altresì, eventuali condizioni generali del DISTRIBUTORE.
  </p>
  `,
  P2: `
  <h3>2. Definizioni</h3>
  <p>I termini di seguito indicati hanno il significato qui precisato:</p>
  <ul>
    <li>
      Parti: sono COMMERCIO e il DISTRIBUTORE che ha aderito al presente
      Accordo;
    </li>
    <li>
      Distributore: è il soggetto che ha aderito al presente Accordo e svolge
      l’attività di promozione di seguito disciplinata. Può trattarsi di
      persona fisica che agisce per scopi estranei all'attività
      imprenditoriale, commerciale, artigianale o professionale eventualmente
      svolta ai sensi art. 3, comma 1 lett. a del D.Lgs. n. 206 del 2005
      (rispettivamente “Consumatore” e “Codice del Consumo”) oppure di
      soggetto (persona fisica o giuridica) dotato di partita iva, che agisce
      nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverso dai soggetti indicati dall’articolo
      3, comma 1, lett. a-b, del Codice del Consumo;
    </li>
    <li>
      Clienti: sono i soggetti a cui il DISTRIBUTORE rivolge la propria
      attività di promozione. Può trattarsi di Consumatori oppure di persone,
      fisiche o giuridiche), dotate di partita iva e che agiscono
      nell’esercizio della propria attività professionale, imprenditoriale,
      commerciale o artigianale e diverse dai soggetti indicati dall’articolo
      3, comma 1, lett. a-b, del Codice del Consumo;
    </li>
    <li>
      Commercio.network è un ecosistema blockchain conforme al regolamento
      europeo eIDAS sviluppato da COMMERCIO che permette ai suoi USERS e
      MEMBERS di creare una identità elettronica e di firmare, notarizzare,
      archiviare e scambiare tra loro documenti digitali con efficacia legale;
    </li>
    <li>
      Utility token: tipo di cripto-attività destinata unicamente a fornire
      l’accesso ad un bene o ad un servizio prestato dal suo emittente (art.
      3, comma 1, n. 9 Regolamento Comunità Europea n. 2023/1114/UE del
      31/05/2023 cd. MICAR).
    </li>
    <li>
      Commercio token (simbolo COM): sono Utility token con lo scopo di
      rendere sicura la rete attraverso il meccanismo di staking, che sono
      generati da Commercio.network in numero predeterminato di 60 milioni e
      con valore determinato dal mercato, il cui acquisto è imponibile secondo
      aliquota IVA ordinaria ai sensi del decreto del Presidente della
      Repubblica n. 633/1972;
    </li>
    <li>
      Commercio Cash Credit (simbolo CCC): sono Utility token con lo scopo di
      eseguire le transazioni sulla rete, che sono generati da
      Commercio.network in numero non predeterminato e con valore fisso di
      euro 1,00 (uno), oltre ad aliquota IVA ordinaria ai sensi del decreto
      del Presidente della Repubblica n. 633/1972;
    </li>
    <li>
      Account: il complesso dei dati identificativi del MEMBER acquisiti
      tramite i fattori di autenticazione della APP COMMERC.IO per consentire
      al Member di accedere all’APP abilitante, al Wallet ed agli altri
      servizi o prodotti di COMMERCIO;
    </li>
    <li>
      APP COMMERC.IO: l’applicazione informatica di COMMERCIO per utilizzare i
      servizi della blockchain Commercio.network;
    </li>
    <li>
      NODO DISTRIBUTORE: è il sito web creato da COMMERCIO, collegato in modo
      univoco al singolo DISTRIBUTORE attraverso codice e indirizzo URL
      UNIVOCO attribuito al momento della sua registrazione in qualità di
      DISTRIBUTORE, che il DISTRIBUTORE dovrà utilizzare per l’attività di
      promozione (esempio: https:// nomedistributore.commerc.io);
    </li>
    <li>
      Membership: si intende l’iscrizione annuale alla blockchain
      Commercio.network che si acquista alla sottoscrizione del relativo
      accordo tramite procedura informatica, a cui si accede dal link UPGRADE
      all’interno dell’APP COMMERC.IO;
    </li>
    <li>
      Member: i soggetti che sottoscrivono il relativo accordo di Membership,
    </li>
    <li>
      User: è la persona (fisica o giuridica) che ha completato la procedura
      di registrazione sull’APP COMMERC.IO, sottoscrivendo il relativo
      accordo;
    </li>
    <li>
      REWARD NODI: compenso in Euro che il DISTRIBUTORE riceve per l’attività
      di promozione svolta in via diretta nei confronti dei Clienti, secondo
      le condizioni e i termini del presente Accordo;
    </li>
    <li>
      Compenso: è il compenso in Euro che il DISTRIBUTORE riceve per
      l’attività di promozione diretta, nei confronti dei Clienti, e per
      quella indiretta, attraverso altri Distributori;
    </li>
    <li>
      Progetto: è il progetto di sviluppo della blockchain Commercio.network
      ed è disponibile al link
      <a href="https://commercio.network/it/progetto" target="_blank"
        >https://commercio.network/it/progetto</a
      >;
    </li>
    <li>
      VBR (Validation Block Rewards): è il compenso in COM che il DISTRIBUTORE
      riceve, a determinate condizioni, per l’attività di Messa in Stake di
      COM mediante delega al Nodo Validatore costituito da COMMERCIO;
    </li>
    <li>
      Nodi Validatori: sono i server collegati tra loro in modo da creare una
      infrastruttura distribuita e decentralizzata per eseguire l’attività di
      validazione delle transazioni che avvengono sulla blockchain
      Commercio.network;
    </li>
    <li>
      Staking (o Messa in Stake): è l’azione di bloccare una certa quantità di
      COM per un periodo minimo di 21 giorni per partecipare, mediante delega
      al Nodo Validatore di COMMERCIO, al processo di consenso distribuito
      delle transazioni sulla blockchain Commercio.network secondo il
      protocollo di Proof of Stake, ricevendo in ricompensa una certa quantità
      di COM;
    </li>
    <li>
      Software: è il software sviluppato da COMMERCIO che è necessario per
      l’utilizzo della blockchain Commercio.network;
    </li>
    <li>
      Wallet: è il hosted wallet di proprietà del MEMBER, che contiene i COM e
      i CCC;
    </li>
    <li>Logo: si intende quanto indicato all’articolo 13;</li>
    <li>Sito web: si intende quanto indicato all’articolo 13.</li>
  </ul>
  `,
  P3: `
  <h3>3. Oggetto dell’Accordo</h3>
  <h4>3.1.</h4>
  <p>
    Questo Accordo disciplina i termini e le condizioni in base alle quali il
    DISTRIBUTORE potrà promuovere nei confronti dei Clienti, nell’Area
    Economica Europea (EEA), la vendita dei prodotti e dei servizi di
    COMMERCIO indicati in questo Accordo dietro riconoscimento del COMPENSO di
    cui all’art. 7. L’Accordo disciplina anche le condizioni della licenza
    d’uso gratuito del Logo di cui all’art. 13 a favore del DISTRIBUTORE per
    la migliore esecuzione del presente incarico, nonché i termini di utilizzo
    del Sito Web e dell’APP COMMERC.IO.
  </p>
  <h4>3.2.</h4>
  <p>
    L’attività di promozione come DISTRIBUTORE presuppone che il soggetto sia
    già titolare di una Membership in corso di validità e che abbia aderito al
    presente Accordo. Tutte le tipologie di Membership prevedono la facoltà di
    effettuare l’attività di promozione e di aderire al presente Accordo, ad
    esclusione della Membership Green.
  </p>
  <h4>3.3.</h4>
  <p>
    Il DISTRIBUTORE non dovrà corrispondere a COMMERCIO alcun prezzo o costo
    per l’adesione al presente Accordo, fermo restando il canone annuo della
    Membership.
  </p>
  <h4>3.4.</h4>
  <p>
    L’attività di promozione riguarda l’acquisto di c.d. Bundle (pacchetti di
    Membership e CCC) e degli altri prodotti e servizi elencati nella pagina
    https://commerc.io. Contestualmente alla sottoscrizione del presente
    Accordo verrà creata da COMMERCIO il sito web (NODO DISTRIBUTORE)
    collegato in modo univoco al singolo DISTRIBUTORE.
  </p>
  <h4>3.5.</h4>
  <p>
    Il DISTRIBUTORE potrà svolgere la propria attività di promozione
    esclusivamente attraverso il NODO DISTRIBUTORE, pena il mancato
    riconoscimento di ogni eventuale Compenso.
  </p>
  <h4>3.6.</h4>
  <p>
    I CLIENTI acquistano i prodotti e servizi sempre e solo da COMMERCIO e non
    dal DISTRIBUTORE. Le modalità di acquisto dei prodotti e servizi sono le
    seguenti: A) accedendo al sito web univoco del Distributore e completando,
    tramite quel sito web, la procedura di registrazione e di acquisto della
    Membership; oppure B) a seguito di ricezione di un'email da parte del
    Distributore, aderendo al link di “invito” contenuto in tale email e
    completando la procedura di registrazione e di acquisto della Membership
    correlata a tale invito.
  </p>
  <h4>3.7.</h4>
  <p>
    Il DISTRIBUTORE non avrà alcun potere di rappresentanza di COMMERCIO nei
    confronti di terzi e quindi non potrà assumere alcun obbligo o diritto a
    carico o a beneficio di quest’ultima società.
  </p>
  <h4>3.8.</h4>
  <p>
    Il DISTRIBUTORE non sarà obbligato nei confronti di COMMERCIO a svolgere
    l’attività di promozione, che svolgerà in modo autonomo, senza vincolo di
    collaborazione continuativa e senza vincolo di esclusiva. Il DISTRIBUTORE
    non avrà diritto ad essere rimborsato da COMMERCIO per le spese sostenute,
    né COMMERCIO fornirà al DISTRIBUTORE alcun materiale di qualsivoglia
    genere (es. brochure, gadget, ecc.). Tutte le spese, i costi, i rischi, le
    perdite ecc. relativi all’attività di promozione oggetto dell’Accordo
    saranno sostenuti dal DISTRIBUTORE.
  </p>
  <h4>3.9.</h4>
  <p>
    Il DISTRIBUTORE avrà facoltà di avvalersi di terzi per svolgere l’attività
    prevista in Accordo e resterà l’unico responsabile nei confronti di
    COMMERCIO per il caso di inosservanza. L’incarico a terzi da parte del
    DISTRIBUTORE non determinerà l’insorgenza di alcun rapporto diretto o
    indiretto dei terzi con COMMERCIO.
  </p>
  `,
  P4: `
  <h3>4. Come si diventa DISTRIBUTORE</h3>
  <h4>4.1.</h4>
  <p>La procedura per diventare un Distributore è la seguente:</p>
  <ul>
    <li>
      effettuare la propria registrazione quale User, aderendo al relativo
      accordo;
    </li>
    <li>
      poi effettuare la propria registrazione quale Member, aderendo al
      relativo accordo;
    </li>
    <li>
      reare un Account riservato, costituito da email e password secondo
      quanto disciplinato nel contratto di acquisto della Membership. La
      Membership acquistata deve rientrare tra quelle che consentono
      l’implementazione per diventare DISTRIBUTORE (cd. Upgrade);
    </li>
    <li>
      si effettua l’Upgrade per diventare DISTRIBUTORE mediante accesso al
      link “Upgrade” e si aderisce al presente Accordo tramite procedimento
      informatico (cd. Point & Click).
    </li>
  </ul>
  <h4>4.2.</h4>
  <p>
    Il soggetto che intende aderire al presente Accordo è consapevole ed
    accetta che ogni fase di registrazione per diventare DISTRIBUTORE è
    gestita dall’APP COMMERC.IO, così come tutte le fasi di esecuzione e
    cessazione dell’Accordo. Inoltre il DISTRIBUTORE è consapevole che, per
    svolgere la propria attività, dovrà munirsi di CCC in quantità sufficiente
    a coprire i costi di invito di cui all’art. 6 e ogni altra transazione
    sulla blockchain di Commercio.network.
  </p>
  <h4>4.3.</h4>
  <p>
    La registrazione all’APP COMMERC.IO e l’adesione al presente Accordo
    comportano l’espressa dichiarazione di quanto segue:
  </p>
  <ol type="a">
    <li>
      di avere letto attentamente le condizioni e i termini dell’Accordo prima
      della propria adesione e del completamento della relativa procedura
      informatica e di accettarne i termini e le condizioni;
    </li>
    <li>
      di essere consapevole che l’adesione mediante Point & Click equivale ad
      approvazione e sottoscrizione dell’Accordo;
    </li>
    <li>
      di garantire e dichiarare che i dati indicati sono veritieri e che la
      sottoscrizione dell’Accordo non viola alcuna disposizione di legge,
      regolamento o autorizzazione della pubblica autorità e, inoltre, di
      essere consapevole che l’eventuale violazione di tali norme e/o
      autorizzazioni darà facoltà a COMMERCIO di risolvere immediatamente
      l’Accordo ai sensi dell’art. 17;
    </li>
    <li>
      di rispettare le singole condizioni previste in relazione alla
      ricevibilità dei COM e dei CCC e di accettarle;
    </li>
    <li>
      se richiesto, di obbligarsi a compilare in modo completo e veritiero la
      procedura di identificazione e antiriciclaggio (c.d.
      Anti-Money-Laundering e Know-your-client), fornendo i dati previsti per
      legge e un valido documento di identità;
    </li>
    <li>
      di essere consapevole che solo previo acquisto di una Membership
      attraverso l’APP COMMERC.IO e in seguito all’adesione al presente
      Accordo verrà attribuito il NODO DISTRIBUTORE da utilizzare
      nell’attività di promozione, anche al fine del riconoscimento del
      COMPENSO;
    </li>
    <li>
      di essere consapevole e di accettare che tutte le informazioni e le
      procedure effettuate come Member e come DISTRIBUTORE saranno registrate
      nel proprio Account dell’APP COMMERC.IO (ad esempio saranno registrati:
      l’acquisto della Membership, l’adesione all’Accordo, la rendicontazione,
      la liquidazione del COMPENSO, la risoluzione e/o il recesso
      dall’Accordo). Mensilmente COMMERCIO invierà al DISTRIBUTORE l’invito a
      prendere visione delle informazioni e delle operazioni riportate nel
      proprio Account.
    </li>
  </ol>
  <h4>4.4.</h4>
  <p>
    In particolare, con l’accesso al proprio Account, ciascun DISTRIBUTORE
    potrà ricevere ogni mese una mail riepilogativa della propria attività
    svolta, ad esempio :1) i CLIENTI-Member acquisiti da COMMERCIO per effetto
    dell’attività di promozione svolta dal DISTRIBUTORE secondo i termini e le
    modalità del presente Accordo; 2) i COMPENSI maturati.
  </p>
  <h4>4.5.</h4>
  <p>
    Il DISTRIBUTORE è consapevole ed accetta che è consentito un solo Account
    per ciascun soggetto giuridico ed una unica Membership per periodo.
    Pertanto, qualora il DISTRIBUTORE dovesse procedere all’acquisto di una
    nuova Membership finché è in corso di validità una precedente Membership,
    tale nuovo acquisto va inteso come rinuncia rispetto alla Membership
    precedente ed in tal caso l’unica Membership riconosciuta in capo al
    DISTRIBUTORE sarà quella da ultima acquistata.
  </p>
  <h4>4.6.</h4>
  <p>
    Il DISTRIBUTORE è consapevole che la disciplina sugli Utility token è in
    continuo divenire, sia dal punto di vista tecnologico, sia normativo,
    pertanto COMMERCIO si riserva il diritto di chiedere al DISTRIBUTORE, in
    qualsiasi momento e qualora lo ritenga opportuno, ulteriori informazioni
    e/o ulteriori dichiarazioni per consentire a COMMERCIO la cessione dei CCC
    o dei COM o il pagamento del Compenso e/o per consentire la fornitura dei
    servizi. La richiesta di informazioni o dichiarazioni verrà inviata alla
    email del DISTRIBUTORE con indicazione specifica del termine e delle
    modalità con cui egli dovrà fornirle.
  </p>
  `,
  P5: `
  <h3>5. Durata dell’Accordo e facoltà di recesso</h3>
  <h4>5.1.</h4>
  <p>
    L’Accordo ha efficacia dal momento in cui COMMERCIO accetta la richiesta
    di adesione effettuata dal Member che intende diventare DISTRIBUTORE e che
    sia stata da questi formulata piattaforma elettronica (premendo il link
    “Upgrade”) Il DISTRIBUTORE è informato che le transazioni elettroniche
    relative a COM e/o CCC (compresi i VBR) vengono processate sulla
    blockchain Commercio.network con esecuzione immediata, cioè durante il
    periodo di recesso di quattordici giorni dalla conclusione del presente
    Accordo che è previsto a favore dei Consumatori dall’art. 52 cd. Codice
    del Consumo per i contratti a distanza (sono i contratti conclusi senza la
    presenza fisica e simultanea delle Parti, come avviene per l’adesione al
    presente Accordo). Pertanto, ai sensi dell’art. 59 Codice del Consumo, il
    DISTRIBUTORE accetta di perdere il diritto al recesso previsto nella
    normativa in materia di contratti conclusi con i Consumatori.
  </p>
  <h4>5.2.</h4>
  <p>
    L’Accordo ha durata di un anno, con tacito rinnovo per un pari periodo,
    salvo disdetta da effettuarsi entro 60 (sessanta) giorni di calendario
    precedenti alla scadenza dell’Accordo. COMMERCIO invierà al DISTRIBUTORE,
    alla casella di posta elettronica indicata al momento della registrazione,
    una comunicazione di segnalazione del rinnovo automatico dell’Accordo con
    preavviso non inferiore a 90 giorni di calendario.
  </p>
  <h4>5.3.</h4>
  <p>
    Per escludere il tacito rinnovo ed effettuare la disdetta, il DISTRIBUTORE
    dovrà eseguire la procedura di cancellazione presente nella pagina
    riservata dell’Account con accesso al link “CANCELLA MEMBERSHIP". Il
    DISTRIBUTORE è consapevole ed accetta che la procedura di disdetta e
    mancato rinnovo del presente Accordo equivale anche al recesso dal
    contratto di Membership e pertanto, con la disdetta del presente Accordo,
    avverrà automaticamente anche il recesso dal contratto come Member.
  </p>
  <h4>5.4.</h4>
  <p>
    A seguito di cancellazione della Membership da parte del DISTRIBUTORE,
    COMMERCIO renderà inattivo il relativo NODO DISTRIBUTORE, senza ulteriore
    avviso.
  </p>
  <h4>5.5.</h4>
  <p>
    Il DISTRIBUTORE è consapevole ed accetta che la disdetta dal presente
    Accordo e l’automatico recesso dal contratto di Membership comportano
    anche la chiusura del Wallet secondo quanto previsto nel contratto di
    Membership.
  </p>
  `,
  P6: `
  <h3>6. Acquisti dei Clienti e attribuzione dei REWARD NODI</h3>
  <h4>6.1.</h4>
  <p>
    Per il riconoscimento dei Reward Nodi al DISTRIBUTORE, sono possibili due
    diverse procedure di acquisto dei beni o dei servizi da parte dei Clienti:
  </p>
  <h5>6.1.1.</h5>
  <p>
    Procedura di acquisto mediante Invito: il DISTRIBUTORE potrà inviare un
    invito (di seguito “Invito”) utilizzando la funzione “inviti” presente
    nell’APP COMMERC.IO, inserendo l’indirizzo email del potenziale Cliente a
    cui intende inviare l’Invito. Il sistema informatico invierà a tale
    indirizzo di posta elettronica una email contenente un link, che darà
    accesso alla pagina di acquisto di Membership, o CCC, o beni e servizi
    sull’APP COMMERC.IO. Il potenziale CLIENTE, al fine di effettuare
    l’acquisto, dovrà procedere alla previa registrazione all’APP COMMERC.IO e
    alla adesione al relativo accordo quale User. Ogni acquisto effettuato da
    parte del Cliente mediante accesso al link dell’Invito risulterà collegato
    al DISTRIBUTORE che ha inviato tale Invito con riconoscimento dei relativi
    Reward.
  </p>
  <h5>6.1.2.</h5>
  <p>
    Procedura di acquisto mediante sito web del Distributore: i potenziali
    CLIENTI potranno acquistare i prodotti o servizi di COMMERCIO (inclusi
    Membership e CCC) mediante accesso al sito web univoco del DISTRIBUTORE
    presente sull’APP COMMERC.IO. Al fine di effettuare l’acquisto tramite
    sito web del DISTRIBUTORE, sarà necessario che il potenziale CLIENTE
    effettui la previa registrazione all’APP COMMERC.IO quale User,
    completando la relativa procedura e aderendo al relativo accordo. Il
    sistema informatico registrerà ogni accesso dei potenziali Clienti del
    sito web del DISTRIBUTORE e ogni acquisto effettuato da parte del Cliente
    mediante accesso a tale sito sull’APP COMMERC.IO risulterà collegato al
    rispettivo DISTRIBUTORE con riconoscimento dei relativi Reward Nodi.
  </p>
  <h4>6.2.</h4>
  <p>
    Ogni vendita di prodotti o servizi avviene direttamente tra COMMERCIO e il
    CLIENTE e non tra il DISTRIBUTORE ed il CLIENTE. La relativa cessione
    verrà indicata nell’Account del DISTRIBUTORE che ha mandato l’Invito o
    alla cui pagina web univoca il Cliente abbia effettuato l’accesso. Questo
    Accordo non disciplina la cessione di beni e servizi di COMMERCIO da parte
    del DISTRIBUTORE ai CLIENTI o a terzi.
  </p>
  <h4>6.3.</h4>
  <p>
    Il CLIENTE munito di un tipo di Membership che consenta l’Upgrade a
    Distributore e che decida di sottoscrivere il presente Accordo per
    svolgere attività di promozione, diverrà a sua volta un DISTRIBUTORE e gli
    verrà attribuito un nuovo codice distributore collegato al DISTRIBUTORE
    che lo ha invitato o dalla cui pagina web univoca abbia effettuato gli
    acquisti. Ogni DISTRIBUTORE farà quindi parte di una catena di promozione
    con un proprio livello.
  </p>
  <h4>6.4.</h4>
  <p>
    Il meccanismo di attribuzione del collegamento tra DISTRIBUTORI opererà
    fino al limite massimo del quarto distributore successivo al primo
    DISTRIBUTORE (es. il codice del quinto distributore sarà collegato ai
    codici del quarto, del terzo, del secondo e del primo distributore, ma non
    avrà alcun collegamento con il Codice Distributore di chi sia stato
    inviato dal quinto distributore della catena di promozione).
  </p>
  <h4>6.5.</h4>
  <p>
    I DISTRIBUTORI i cui Codici Distributore siano collegati tra loro potranno
    percepire i Compensi indicati all’articolo 7. Nell’ipotesi in cui un
    DISTRIBUTORE collegato non rinnovi l’adesione al presente Accordo e non
    abbia più la qualifica di DISTRIBUTORE, gli altri DISTRIBUTORI a lui
    collegati conserveranno il loro livello nella catena dei collegamenti ai
    fini della attribuzione e del calcoli dei rispettivi Compensi.
  </p>
  `,
  P7: `
  <h3>7. COMPENSO, oneri e termini di pagamento</h3>
  <h4>7.1.</h4>
  <p>
    Per ciascun acquisto effettuato da un CLIENTE che abbia ricevuto l’Invito
    o che abbia fatto accesso al sito web univoco del Distributore, il
    DISTRIBUTORE avrà diritto al COMPENSO di seguito esposto, da calcolarsi in
    base alle tabelle presenti sul sito HTTPS://COMMERC.IO e in vigore al
    momento dell’acquisto dei prodotti e servizi di COMMERCIO da parte dei
    Clienti promossi dal DISTRIBUTORE. Nella medesima tabella indicata saranno
    riportate anche le percentuali di Compenso attribuite ai diversi livelli
    di DISTRIBUTORE rispetto alla catena dei collegamenti, così come descritta
    nell’articolo precedente.
  </p>
  <h4>7.2.</h4>
  <p>La disciplina dei REWARD NODI è la seguente:</p>
  <ol type="a">
    <li>
      Compenso in EURO da calcolare in base alla relativa tabella sopra
      citata, per ciascuna tipologia di Membership acquistata dai CLIENTI per
      effetto dell’attività di promozione e secondo il tipo di procedura di
      acquisto tra le due descritte all’articolo precedente.
    </li>
    <li>
      Ogni Membership dà diritto a servizi o prodotti di COMMERCIO diversi,
      come previsto nel contratto di Membership e ad ogni tipologia di
      Membership è attribuito un Compenso differenziato e crescente, a partire
      dalla Green Membership fino alla Platinum Membership.
    </li>
    <li>
      Ciascun DISTRIBUTORE potrà ricevere i REWARD NODI solo a condizione che
      la Membership acquistata dal CLIENTE sia di livello pari o inferiore
      rispetto alla Membership acquistata dal DISTRIBUTORE e in corso di
      validità al momento dell’acquisto da parte del CLIENTE della propria
      Membership (es. il DISTRIBUTORE titolare di una Membership Silver potrà
      ricevere i Reward Nodi solo se il Cliente, destinatario dell’Invito,
      acquisterà da COMMERCIO una Membership Bronze, o Red, i Blue, mentre non
      riceverà alcun Reward Nodi se il CLIENTE acquisterà una Membership Gold
      o Platinum).
    </li>
    <li>
      Qualora il CLIENTE acquisti una Membership di livello superiore rispetto
      a quella del DISTRIBUTORE, COMMERCIO invierà al DISTRIBUTORE una
      comunicazione a mezzo email per informarlo di tale acquisto e per
      avvisarlo che avrà tempo 30 giorni di calendario per acquistare una
      nuova Membership di livello pari o superiore a quella acquistata dal
      CLIENTE al fine di vedersi riconosciuto il Reward Nodi di
      quell’acquisto. Il costo della nuova Membership sarà così calcolato: per
      l’acquisto di nuova Membership di livello superiore effettuato almeno 6
      mesi prima della scadenza della Membership di livello inferiore in corso
      di validità, sarà pari al 50 % del costo della differenza di prezzo tra
      le due Membership di diverso livello; qualora invece l’acquisto di una
      nuova Membership di livello superiore sia effettuato meno di sei mesi
      prima della scadenza della Membership di livello inferiore in corso di
      validità, sarà pari all’intero importo della Membership di livello
      superiore.
    </li>
    <li>
      Qualora il DISTRIBUTORE non proceda all’acquisto della nuova Membership
      di grado superiore nel termine qdi 30 giorni di calendario, perderà ogni
      diritto a vedersi riconosciuto il Reward Nodi relativo all’acquisto
      della Membership superiore da parte del CLIENTE;
    </li>
  </ol>
  <h4>7.3.</h4>
  <p>
    Il DISTRIBUTORE riconosce e accetta di corrispondere a COMMERCIO, tramite
    la blockchain Commercio.network, una commissione variabile da (0.01 CCC a
    0,25 CCC), a seconda della tipologia di Membership di ciascun
    DISTRIBUTORE, per ogni singolo Invito trasmesso ad un CLIENTE tramite NODO
    DISTRIBUTORE nell’ambito dell’attività di promozione oggetto di Accordo.
    Il costo commissione sarà decurtato automaticamente dal sistema, ad ogni
    Invito.
  </p>
  <h4>7.4.</h4>
  <p>
    COMMERCIO, tramite mail mensile (Mail Riepilogativa Mensile), riporterà al
    DISTRIBUTORE ogni acquisto effettuato da un CLIENTE a seguito della
    promozione svolta da quest’ultimo nel corso del mese e andata a buon fine
    (cioè vendita per la quale COMMERCIO riceve il pagamento del prezzo). Il
    DISTRIBUTORE ha l’onere di contestare i dati riportati nella Mail
    Riepilogativa Mensile entro 48 ore dalla ricezione, a pena di decadenza. I
    DISTRIBUTORI dotati di partita iva dovranno emettere fattura intestata a
    Commercio.network SPA recante il codice univoco.
  </p>
  <h4>7.5.</h4>
  <p>
    In caso di cessazione dell’Accordo, il DISTRIBUTORE avrà diritto al
    Compenso sulle vendite andate a buon fine sino al giorno dalla scadenza
    dell’Accordo.
  </p>
  `,
  P8: `
  <h3>
    8. Corso, Loghi, personalizzazione del Nodo Distributore e limitazioni
    all’attività
  </h3>
  <h4>8.1.</h4>
  <p>
    Il DISTRIBUTORE, per tutta la durata del presente Accordo, potrà fruire di
    un corso dedicato alla vendita delle Membership. Il corso è gratuito ed è
    costituito da video creati da COMMERCIO e fruibili tramite l’APP
    COMMERC.IO (di seguito anche “Contenuti Digitali”) di proprietà e/o nella
    disponibilità esclusiva di COMMERCIO, di cui quest’ultima detiene tutti i
    diritti di utilizzo e di sfruttamento economico. I Contenuti Digitali
    possono essere fruiti solamente mediante accesso all’APP COMMERCIO con le
    modalità previste nell’Accordo User e non possono essere prelevati in
    modalità downloading, secondo quanto previsto nelle condizioni di utilizzo
    dell’APP COMMERCIO previste nell’Accordo User.
  </p>
  <h4>8.2.</h4>
  <p>
    Ciascun DISTRIBUTORE potrà personalizzare il proprio NODO DISTRIBUTORE
    inserendo un proprio logo e colore oppure potrà aggiungere dei contenuti
    digitali (es. video o testi). A tale fine COMMERCIO renderà disponibile un
    menu di settings per poter personalizzare il proprio sito.
  </p>
  <h4>8.3.</h4>
  <p>
    Il DISTRIBUTORE, con l’inserimento di tali loghi, dichiara di esserne il
    legittimo proprietario o, comunque, di essere legittimato al relativo
    utilizzo ed esonera e manleva COMMERCIO da qualsivoglia responsabilità
    verso il DISTRIBUTORE stesso o verso terzi derivanti dall’utilizzo di tali
    loghi, front, video, testi o contenuti di qualsiasi forma e genere
    inseriti dal DISTRIBUTORE nel proprio NODO DISTRIBUTORE. Il DISTRIBUTORE
    sarà altresì responsabile di qualsiasi danno subito da COMMERCIO a causa
    dell’utilizzo di tali loghi o genere di contenuti di qualsiasi forma nel
    proprio NODO DISTRIBUTORE.
  </p>
  <h4>8.4.</h4>
  <p>
    A pena di risoluzione immediata dell’Accordo ai sensi dell’art. 17, nello
    svolgimento della sua attività di promozione il DISTRIBUTORE NON dovrà
    svolgere alcuna delle seguenti attività:
  </p>
  <ol type="a">
    <li>
      rivolgere la propria azione verso soggetti con caratteristiche o
      qualifiche diverse da quelle previste dall’Accordo per i CLIENTI (come,
      ad es., i privati);
    </li>
    <li>
      danneggiare l’immagine, la reputazione o la credibilità della società
      COMMERCIO (inclusi i suoi dipendenti e i suoi organi e rappresentanti),
      dei partners e clienti di COMMERCIO o nei confronti di altri
      DISTRIBUTORI, né rilasciare dichiarazioni false, fuorvianti o
      denigratorie in relazione a Commercio.network e ai relativi servizi e
      prodotti;
    </li>
    <li>
      rilasciare, sotto qualsiasi forma, dichiarazioni relative al valore
      futuro dei TOKEN;
    </li>
    <li>
      utilizzare, in qualsiasi forma o modo, il prezzo futuro del COM come
      argomento di promozione;
    </li>
    <li>
      creare siti web o pubblicità che copiano o imitano Commercio.network e/o
      i suoi servizi e prodotti in modo pedissequo o in modo tale da
      ingenerare confusione sul mercato dei CLIENTI o creano confusione sulla
      natura e i servizi di Commercio.network;
    </li>
    <li>
      copiare, in tutto o in parte, il Sito web di COMMERCIO, o utilizzare il
      nome e il Logo di COMMERCIO per scopi illeciti o, comunque, per scopi
      diversi dalla esecuzione dell’Accordo o in violazione ad esso;
    </li>
    <li>
      utilizzare tecniche di riempimento automatico del Modulo di
      registrazione da parte dei CLIENTI e/o automatizzare sotto qualsiasi
      forma la propria attività di promozione;
    </li>
    <li>
      fare offerte, o utilizzare il Logo o i marchi o la denominazione di
      COMMERCIO o di Commercio.network, o usare parole chiave errate ai fini
      della pratica c.d. pay-per-click sui motori di ricerca internet;
    </li>
    <li>
      utilizzare, nel nome o nei nomi di dominio, il Logo o i marchi o la
      denominazione di COMMERCIO o di Commercio.network o usare parole chiave
      in modo illecito;
    </li>
    <li>
      utilizzare materiale di marketing o di pubblicità su qualsiasi sito web
      che promuova violenza, discriminazione basata su razza, sesso,
      religione, nazionalità, disabilità, orientamento sessuale o età, o
      qualsiasi attività illegale, o che violi la proprietà intellettuale o
      altri diritti di terzi;
    </li>
    <li>
      inviare e-mail in violazione del Regolamento Europeo n. UE/2016/679 GDPR
      e senza aver ottenuto il consenso dell’interessato;
    </li>
    <li>
      inviare e-mail di marketing per promuovere i prodotti e servizi di
      COMMERCIO idonei a trarre in inganno o a creare incertezza sulla
      identità del mittente;
    </li>
    <li>
      utilizzare malware o spyware per commercializzare o promuovere i servizi
      e prodotti di COMMERCIO.
    </li>
  </ol>
  <h4>8.5.</h4>
  <p>
    Inoltre, sempre a pena il risoluzione immediata dell’Accordo ai sensi
    dell’art. 17, il DISTRIBUTORE dichiara e garantisce:
  </p>
  <ol type="a">
    <li>
      di assicurarsi che il NODO DISTRIBUTORE funzioni correttamente prima di
      inviare il traffico di inviti;
    </li>
    <li>
      di palesare in modo inequivocabile ai CLIENTI di essere lui, e non
      COMMERCIO, il mittente di tutte le comunicazioni di promozione relative
      ai prodotti e servizi di COMMERCIO.
    </li>
  </ol>
  `,
  P9: `
  <h3>9. SWAP – MESSA IN STAKE e Staking Rewards</h3>
  <h4>9.1.</h4>
  <p>
    Ciascun DISTRIBUTORE avrà facoltà di procedere al cambio di CCC in COM
    mediante la funzione SWAP presente nel Wallet. Il cambio dei CCC in COM
    avverrà secondo il tasso di cambio fisso di 1:1 (uno a uno).
  </p>
  <h4>9.2.</h4>
  <p>
    Ciascun DISTRIBUTORE avrà facoltà di procedere alla Messa in Stake di
    propri COM presso il Nodo Validatore COMMERCIO, utilizzando la funzione
    “STAKE” presente nell’APP COMMERC.IO, eventualmente, anche previo cambio
    di CCC in COM con l’utilizzo della funzione SWAP di cui sopra.
  </p>
  <h4>9.3.</h4>
  <p>
    Questa funzione di Messa in Stake ha solo lo scopo di rendere sicura la
    rete di commercio.network tramite i meccanismo del Proof of stake, che
    permette di vincolare una certa quantità di token su un nodo validatore
    che svolge l’attività di validazione di tutte le transazioni della
    blockchain e non deve essere assolutamente utilizzata ai fini speculativi
    da parte del DISTRIBUTORE.
  </p>
  <h4>9.4.</h4>
  <p>
    Il DISTRIBUTORE è consapevole ed accetta che i VBR verranno corrisposti da
    COMMERCIO in COM, secondo il meccanismo di accumulo di Validation Block
    Rewards (VBR) che è esplicitato nel Progetto e per il quale si rinvia al
    link docs.commercio.network . In particolare, il DISTRIBUTORE è
    consapevole ed accetta che: A) sulla quantità di VBR maturati in
    conseguenza della Messa in Stake verrà applicata una commissione del 10% a
    favore del Nodo Validatore delegato dal DISTRIBUTORE per l’attività di
    validazione svolta; inoltre B) la quantità di COM delegata al Nodo
    Validatore per la Messa in Stake sarà soggetta a decurtazione nella misura
    dall’1% al 5% qualora il Nodo Validatore delegato esegua una doppia firma
    di validazione di una transazione della blockchain oppure manchi di tenere
    attivo il server per un periodo superiore a 15 ore.
  </p>
  <h4>9.5.</h4>
  <p>
    Il DISTRIBUTORE è consapevole e accetta che il Progetto potrà essere
    variato, modificato ed aggiornato senza che ciò comporti alcun diritto per
    il DISTRIBUTORE di indennizzo o rimborso o risarcimento per l’eventuale
    differenza tra la quantità di VBR che dovesse essere ipotizzabile e
    calcolabile al momento dell’acquisto dei COM e/o della loro Messa in Stake
    e quella risultante al momento dell’effettivo calcolo dei COM maturati ed
    il loro accredito a favore del DISTRIBUTORE.
  </p>
  `,
  P10: `
  <h3>10. Limitazioni di garanzia</h3>
  <h4>10.1. Limitazione di garanzia relativa all’APP COMMERC.IO.</h4>
  <p>
    Il DISTRIBUTORE riconosce ed accetta che l’APP COMMERC.IO da utilizzare ai
    fini della attività di promozione mediante il NODO DISTRIBUTORE, potrebbe
    essere soggetta ad errori di sistema o malfunzionamenti che in nessun caso
    potranno dare luogo a responsabilità di COMMERCIO.
  </p>
  <h4>10.2. Limitazione di garanzia concernente COM e CCC.</h4>
  <h5>10.2.1.</h5>
  <p>
    Il DISTRIBUTORE riconosce, comprende e qui espressamente accetta che i COM
    e/o i CCC vengono ceduti e potranno essere utilizzati "COSÌ COME SONO" e
    "COME DISPONIBILI" (ovvero “As is”) senza promessa o garanzia da parte di
    COMMERCIO di altre qualità, caratteristiche o funzionalità che non siano
    quelle descritte di seguito. Il DISTRIBUTORE dichiara di essere stato
    adeguatamente informato:
  </p>
  <ul>
    <li>sulle funzionalità e sulle caratteristiche dei COM e dei CCC;</li>
    <li>
      sul fatto che i COM e i CCC saranno ceduti al DISTRIBUTORE,
      conformemente al Progetto. Successivamente i COM potranno essere
      acquistati presso exchange che li inseriscano nel loro listino o, in
      alternativa, tramite scambio diretto con altri soggetti che li
      detengono.
    </li>
  </ul>
  <h5>10.2.2.</h5>
  <p>
    Il DISTRIBUTORE dichiara di essere consapevole che COMMERCIO non rilascia
    alcuna dichiarazione o garanzia, espressa o implicita, di commerciabilità
    o cambio dei COM in criptovalute o in moneta avente corso legale, né di
    idoneità dei COM per scopi e utilizzi che siano diversi e ulteriori
    rispetto alla Messa in Stake.
  </p>
  <h5>10.2.3.</h5>
  <p>Il DISTRIBUTORE è consapevole ed accetta che:</p>
  <ol type="a">
    <li>
      ricevere CCC o COM può avere conseguenze fiscali per lui e di essere il
      solo responsabile dell’adempimento degli obblighi fiscali eventualmente
      connessi alla ricezione e/o al possesso dei CCC o dei COM;
    </li>
    <li>
      i CCC e i COM possono essere ceduti solamente a terzi che dispongono di
      wallet che soddisfano gli standard NATIVI COSMOS;
    </li>
    <li>
      i CCC e i COM non sono un prodotto o servizio finanziario e non
      costituiscono una forma di investimento, né una forma di finanziamento.
      Il presente Accordo e tutti gli altri documenti qui richiamati non
      costituiscono prospetti, quotazioni, offerte di investimento, offerte di
      titoli o offerte di scambio volte a ricevere titoli o merci;
    </li>
    <li>
      i CCC e i COM non sono una cripto-valuta, né intesa come token di moneta
      elettronica (ossia un tipo di cripto-attività che mira a mantenere un
      valore stabile facendo riferimento al valore di una valuta ufficiale ai
      sensi dell’art. 3, comma 1, n. 7 MICAR), né un token collegato ad
      attività (ossia un tipo di cripto-attività che non è un token moneta
      elettronica e che mira a mantenere un valore stabile facendo riferimento
      a un altro valore o diritto o a una combinazione dei due, comprese una o
      più valute ufficiali ai sensi dell’art. 3, comma 1, n. 6 MICAR)
    </li>
    <li>
      CCC e i COM non costituiscono partecipazioni societarie, né danno alcun
      diritto patrimoniale e/o amministrativo relativo a COMMECIO. A titolo
      esemplificativo e non esaustivo, essi non attribuiscono il diritto a
      dividendi, o a beni, o a redditi di COMMERCIO, né danno diritto a
      rimborsi e liquidazioni, o diritto di partecipazione ad assemblee, o di
      voto, o altri diritti finanziari o legali;
    </li>
    <li>
      i CCC e i COM non sono attualmente convertibili in valuta ufficiale o in
      cripto-valuta e potrebbero non esserlo in futuro. In particolare, si
      avverte che i CCC o i COM potrebbero non essere mai listati su un
      Exchange Centralizzato (CEX) o su un Exchange Decentralizzato (DEX) e,
      pertanto, potrebbe non costituirsi alcun mercato liquido di scambio dei
      CCC o dei COM;
    </li>
    <li>
      i CCC e i COM potranno essere listati su Exchange Centralizzati (CEX) o
      Exchange Decentralizzati (DEX) solo se altri Utenti o Member, o altri
      titolari di CCC o COM provvederanno in futuro alla creazione di una
      liquidity pool con i CCC o i COM e altra cripto-attività. Anche nella
      ipotesi in cui, in futuro, i CCC o i COM siano listati in un CEX o in un
      DEX, gli stessi potrebbero essere delistati in qualsiasi momento senza
      che ciò comporti alcuna responsabilità in capo a COMMERCIO, neppure per
      la sopravvenuta mancata possibilità di scambiare i CCC o i COM, o per la
      loro perdita di valore. Il DISTRIBUTORE conferma, comprende e accetta
      che, qualora i CCC o i COM fossero listati in CEX o DEX, il loro valore
      sarebbe suscettibile alle regole del mercato e potrebbe variare
      sensibilmente, tanto che i CCC o i COM potrebbero anche non avere alcun
      valore. Il DISTRIBUTORE è consapevole ed accetta che COMMERCIO non potrà
      essere ritenuta responsabile per il loro valore di mercato, la loro
      convertibilità e /o liquidità.
    </li>
  </ol>
  <h5>10.2.4.</h5>
  <p>
    Aderendo al presente Accordo, il DISTRIBUTORE dichiara di avere ricevuto
    sufficienti informazioni relative alle funzionalità e alle caratteristiche
    dei CCC e dei COM.
  </p>
  <h4>
    10.3. Limitazione di garanzia relativa alla blockchain Commercio.network
  </h4>
  <h5>10.3.1.</h5>
  <p>
    Allo stesso modo il DISTRIBUTORE riconosce, comprende ed accetta che la
    blockchain Commercio.network, a cui avrà accesso, è dotata delle
    funzionalità "COSÌ COME SONO" e "COME DISPONIBILI" (ovvero “As is”). Il
    DISTRIBUTORE è consapevole ed accetta che:
  </p>
  <ol type="a">
    <li>
      non riceve alcuna garanzia da parte di COMMERCIO di completa
      realizzazione e/o sviluppo di Commercio.network rispetto al Progetto;
    </li>
    <li>
      che Commercio.network è ancora in fase di sviluppo e potrebbe subire
      modifiche significative nel tempo rispetto al Progetto. Sebbene al
      momento COMMERCIO abbia intenzione di sviluppare tale blockchain in modo
      che essa abbia le caratteristiche e le specifiche stabilite nel
      Progetto, resta inteso che COMMERCIO potrà successivamente apportare, a
      suo insindacabile giudizio, modifiche a tali caratteristiche e
      specifiche, anche se ciò potrebbe comportare il fatto che non soddisfi
      le aspettative del DISTRIBUTORE per ragioni tecniche, tecnologiche,
      legislative o regolamentari, economiche o di mercato;
    </li>
    <li>
      lo sviluppo di Commercio.network potrà essere eventualmente abbandonato
      da COMMERCIO per una serie di motivi oggettivi, tra cui, a titolo
      esemplificativo ma non esaustivo: la mancanza di interesse da parte del
      pubblico, la mancanza di finanziamenti, impedimenti tecnici o
      tecnologici, o impedimenti legislativi o regolamentari;
    </li>
    <li>
      in molte giurisdizioni la definizione e la disciplina a livello
      legislativo o regolamentare dei token crittografici, delle risorse
      digitali e della tecnologia blockchain non è definito ed è comunque
      mutevole. È difficile prevedere come o se le autorità governative
      regoleranno tali tecnologie. Allo stesso modo è difficile prevedere come
      o se un'autorità governativa possa apportare modifiche a leggi,
      regolamenti e / o regole esistenti che influenzeranno i token
      crittografici, le risorse digitali, la tecnologia blockchain e le sue
      applicazioni. Tali modifiche potrebbero avere un impatto negativo sui
      Commercio token e/o sui Commercio Cash Credit in vari modi, tra cui, ad
      esempio, la determinazione che i Commercio token e/o i Commercio Cash
      Credit sono strumenti finanziari regolamentati e quindi richiedono le
      relative autorizzazioni o registrazioni. COMMERCIO potrebbe quindi, a
      sua discrezione o per ordine dell’autorità, cessare la vendita dei
      Commercio token, e/o dei Commercio Cash Credit e/o cessare lo sviluppo
      della blockchain Commercio.network, nel caso in cui azioni governative
      rendano illegale o commercialmente indesiderabile continuare a farlo.<br />
      Conseguentemente, il DISTRIBUTORE dichiara di essere consapevole ed
      accetta che:
      <ul>
        <li>
          la blockchain Commercio.network è stata sviluppata, completata e
          messa in funzione con la versione 5 e non si sa se saranno
          sviluppate e/o messe in funzione altre versioni;
        </li>
        <li>
          le eventuali versioni successive alla versione 5 di
          Commercio.network potrebbero essere sviluppate o realizzate con
          tempistiche diverse da quelle previste nel Progetto;
        </li>
        <li>
          Commercio.network potrebbe essere sviluppata e realizzata con
          modalità, funzionalità e possibilità di utilizzo diverse rispetto a
          quelle indicate nel Progetto;
        </li>
        <li>
          le attività di scambio e/o di firma in forma criptata e
          pseudo-anonima dei documenti tra imprese partecipanti a
          Commercio.network potrebbe non risultare possibile e/o risultare
          eseguibile con modalità, funzionalità e/o costi diversi da quelli
          indicati nel Progetto.
        </li>
      </ul>
    </li>
  </ol>
  `,
  P11: `
  <h3>11. Limiti di indennizzo</h3>
  <p>
    Conseguentemente COMMERCIO non sarà obbligato a risarcire, né ad
    indennizzare il DISTRIBUTORE, né ai suoi aventi causa, nell’ipotesi in
    cui:
  </p>
  <ol type="a">
    <li>
      i Commercio token e Commercio Cash Credit non risultassero convertibili
      con criptovalute e/o con monete aventi corso legale, o fossero
      convertibili con indici di rapporto diversi da quelli sperati o
      ipotizzati o ipotizzabili al momento del loro acquisto;
    </li>
    <li>
      Commercio.network non venisse sviluppata in tutte le sue fasi del
      Progetto, o venisse sviluppata con ritardo o con funzionalità ed utilità
      diverse rispetto a quanto previsto nel Progetto;
    </li>
    <li>
      le attività di notarizzazione e archiviazione tra imprese partecipanti
      alla blockchain non dovessero risultare temporaneamente possibili e/o
      non fosse possibile eseguirle con modalità, funzionalità e/o a costi
      diversi da quelli indicati nel Progetto.
    </li>
  </ol>
  `,
  P12: `
  <h3>12. Proprietà intellettuale</h3>
  <p>
    COMMERCIO manterrà tutti i propri diritti di Proprietà intellettuale ed
    industriale, incluse, senza limitazioni, invenzioni, idee, know-how,
    segreti commerciali, concetti, codici, scoperte, processi, brevetti,
    marchi, metodi, software, composizioni, formule, tecniche, informazioni e
    dati, anche se non brevettabili o protetti da copyright. Il DISTRIBUTORE
    non può utilizzare nessuno dei diritti di proprietà intellettuale o
    industriale di COMMERCIO senza il preventivo consenso scritto di
    quest’ultima.
  </p>
  `,
  P13: `
  <h3>13. Logo, APP COMMERC.IO e Sito web di COMMERCIO</h3>
  <h4>13.1. Logo</h4>
  <h5>13.1.1.</h5>
  <p>
    COMMERCIO è titolare di proprio logo, brand, slogan, simboli, marchi anche di fatto relativi alla blockchain Commercio.network e con l’Accordo intende concedere licenza d’uso di detti logo, slogan, brand, simboli, marchi di fatto (complessivamente il “Logo”) a titolo gratuito e non liberale al DISTRIBUTORE.
  </p>
  <h5>13.1.2.</h5>
  <p>
    In conseguenza di tale concessione di licenza d’uso, una volta che l’Accordo sarà entrato in vigore potrà indicare, nella propria carta intestata, sito web e sulla propria documentazione cartacea o digitale, la propria appartenenza a Commercio.network anche mediante utilizzo del Logo, slogan, brand simboli, marchi anche di fatto della blockchain stessa. 
  </p>
  <h5>13.1.3.</h5>
  <p>
    Il DISTRIBUTORE, per la durata dell’Accordo, ha diritto di utilizzare il Logo, fermo restando ogni diritto patrimoniale e non patrimoniale su di essi in capo a COMMERCIO.
  </p>
  <h5>13.1.4.</h5>
  <p>
    Il DISTRIBUTORE non è autorizzato a registrare alcun nome di dominio o sito web che contenga - in tutto od in parte - la denominazione, il Logo, i brand, gli slogan o simboli ecc. di Commercio.network o di COMMERCIO. 
  </p>
  <h5>13.1.5.</h5>
  <p>
    Alla cessazione dell’Accordo, per qualsiasi ragione, cesserà il diritto di utilizzare in qualsivoglia modo il Logo, i brand, gli slogan, simboli ecc. della blockchain.
  </p>
  <h5>13.1.6.</h5>
  <p>
    Nel caso in cui il DISTRIBUTORE utilizzi in qualche modo, successivamente alla cessazione dell’Accordo, il Logo o qualsivoglia riferimento a Commercio.network, sarà responsabile nei confronti di COMMERCIO per tutti danni patrimoniali e non arrecati.
  </p>
  <h4>13.2. APP COMMERC.IO e Sito web</h4>
  <h5>13.2.1.</h5>
  <p>
    La APP ed il Sito di COMMERCIO sono di esclusiva proprietà di COMMERCIO e possono essere utilizzati dal DISTRIBUTORE solo in esecuzione dell’Accordo, il quale sarà responsabile per gli eventuali danni patrimoniali e non patrimoniali conseguenti alla sua violazione.
  </p>
  <h5>13.2.2.</h5>
  <p>
    Alla cessazione dell’Accordo, per qualsiasi ragione, il DISTRIBUTORE dovrà astenersi dall’utilizzare in qualsivoglia modo l’APP COMMERCIO e il Sito di COMMERCIO.
  </p>
  <h5>13.2.3.</h5>
  <p>
    Nel caso in cui il DISTRIBUTORE utilizzi in qualche modo, successivamente alla cessazione dell’Accordo, l’APP e il Sito di COMMERCIO, sarà responsabile nei confronti di COMMERCIO per tutti danni patrimoniali e non arrecati.
  </p>
  `,
  P14: `
  <h3>14. Clausola di confidenzialità</h3>
  <h4>14.1.</h4>
  <p>
    Le Parti espressamente concordano che ogni informazione, dato, risultato o
    documento relativo al presente Accordo sia mantenuto in regime di
    confidenzialità. Ai fini del Accordo per informazione confidenziale (di
    seguito per brevità “Informazione riservata”) s’intende qualsiasi
    informazione anche di carattere scientifico comprendente, sebbene non
    esclusivamente riferita, a qualsiasi documento rivelato o consegnato da
    COMMERCIO a beneficio del DISTRIBUTORE.
  </p>
  <h4>14.2.</h4>
  <p>
    In particolare le Parti concordano che si intendano riservate tutte le
    Informazioni riservate comunicate, in qualunque forma, verbale, scritta
    elettronica o in qualsiasi altra forma intellegibile, da COMMERCIO al
    DISTRIBUTORE, o comunque venute a conoscenza di quest’ultimo in occasione
    dei rapporti tra le Parti, compresa, a titolo meramente esemplificativo,
    ogni informazione coperta da diritto di proprietà industriale o
    intellettuale (brevetti e relative domande di registrazione, invenzioni
    non registrate, processi, formule, segreti commerciali o industriali,
    know-how, marchi e diritti d’autore e diritti connessi, registrati e non,
    disegni e modelli registrati o relative domande di registrazione,
    software), informazioni e modelli relativi ai processi aziendali,
    informazioni e modelli finanziari e gestionali, nominativi di clienti,
    fornitori, partner commerciali, transazioni commerciali, proposte,
    rapporti, proiezioni di mercato, materiale promozionale, dati o ogni altra
    informazione confidenziale, nonché analisi, documenti di lavoro, raccolte
    di dati, tavole comparative, studi e ogni documento redatto da COMMERCIO
    (o da un suo cliente o collaboratore o consulente o fornitore) nell’ambito
    dello svolgimento dell’attività relativa al presente Accordo che contenga
    o rifletta o sia fondata sulle predette informazioni.
  </p>
  <h4>14.3.</h4>
  <p>
    Le Informazioni riservate sono da considerarsi riservate indipendentemente
    dal fatto che COMMERCIO vi abbia apposta la dicitura “riservato” o
    similari.
  </p>
  <h4>14.4.</h4>
  <p>
    L’obbligo di riservatezza si estende, inoltre, a tutti gli elaborati,
    progetti, software ed ogni documento o dato ecc. realizzato da COMMERCIO
    in esecuzione del presente Accordo, essendo inteso che, sin dall’inizio
    della sua elaborazione, tutto quanto realizzato da COMMERCIO in esecuzione
    di questo Accordo costituisce Informazione riservata ai sensi e per gli
    effetti del presente Accordo.
  </p>
  <h4>14.5.</h4>
  <p>
    Le obbligazioni sopra descritte non si applicano alle informazioni
    confidenziali:
  </p>
  <ol type="a">
    <li>
      di cui la parte ricevente sia in grado di provare di esserne venuta a
      conoscenza prima dell’avvenuto ricevimento delle stesse dall’altra parte
      in forza del presente Accordo; o
    </li>
    <li>
      che sono di dominio pubblico al tempo in cui si sia verificata la
      divulgazione a vantaggio del ricevente o di trovarsi in regime di
      dominio pubblico a seguito di un accadimento che non implichi la rottura
      del presente Accordo; o
    </li>
    <li>
      che sono state legalmente acquisite da parte del ricevente, da una fonte
      indipendente titolare del diritto alla divulgazione delle stesse.
    </li>
  </ol>
  <h4>14.6.</h4>
  <p>
    Le Parti si impegnano a trattare come confidenziale ogni e qualsiasi
    informazione e si impegnano:
  </p>
  <ol type="i">
    <li>
      ad utilizzare le Informazioni riservate esclusivamente a fini
      dell'esecuzione del presente Accordo e a non utilizzare le Informazioni
      riservate, né direttamente, né indirettamente, per scopi diversi da
      quelli previsti dal presente Accordo;
    </li>
    <li>
      a non copiare o riprodurre le Informazioni riservate in alcun modo, né
      consentire a terzi o dipendenti o collaboratori di farlo;
    </li>
    <li>
      non divulgare le stesse a terzi, né tanto meno di renderle pubbliche o
      accessibili con qualsiasi altro mezzo, a meno che non sia espressamente
      consentito tramite un consenso scritto rilasciato da COMMERCIO a favore
      del DISTRIBUTORE;
    </li>
    <li>
      a limitare la comunicazione delle Informazioni Riservate ai propri
      dipendenti, consulenti o collaboratori nella misura strettamente
      necessaria all'esecuzione del presente Accordo. Resta inteso che
      comunque il DISTRIBUTORE resterà responsabile e risponderà nei confronti
      di COMMERCIO in solido con i soggetti materialmente responsabili di ogni
      e qualsiasi violazione del presente Accordo.
    </li>
  </ol>
  <h4>14.7.</h4>
  <p>
    In caso di richiesta di legge, regolamento, pronuncia giudiziale o
    legittima richiesta della autorità di conoscenza delle Informazioni
    riservate, il DISTRIBUTORE dovrà comunicarlo tempestivamente e senza
    indugio alcuno a COMMERCIO.
  </p>
  <h4>14.8.</h4>
  <p>
    Il presente vincolo di segretezza durerà fino a quando le Informazioni
    riservate non siano divenute di dominio pubblico e senza che ciò sia
    avvenuto in violazione del presente Accordo, fermo restando che qualora
    sia divenuto di dominio pubblico uno o più elementi costituenti
    Informazione riservata, l’obbligo di confidenzialità e riservatezza
    resterà in vigore per tutti gli elementi non divenuti di dominio pubblico.
  </p>
  <h4>14.9.</h4>
  <p>
    Alla scadenza dell’Accordo avvenuta per qualsivoglia ragione, il
    DISTRIBUTORE dovrà restituire a COMMERCIO, immediatamente e senza
    trattenerne alcuna copia, ogni documento, disegno, progetto, annotazione,
    elaborazione, prototipo o altro materiale che contenga Informazione
    riservata, compresi i documenti allegati.
  </p>
  `,
  P15: `
  <h3>15. Modifiche all’Accordo</h3>
  <h4>15.1.</h4>
  <p>
    Modifiche unilaterali. COMMERCIO si riserva la facoltà di modificare il
    presente Accordo previa comunicazione al DISTRIBUTORE a mezzo email,
    all’indirizzo indicato con la creazione dell’Account, unicamente nel caso
    in cui tali modifiche si rendano necessarie per adeguare la disciplina
    dell’Accordo alla normativa e/o all’evolversi della tecnologia relativa
    alla blockchain ed ai token. Le modifiche si intenderanno accettate e
    diverranno definitive trascorsi 30 (trenta) giorni dall’invio della
    comunicazione di modifica. Il DISTRIBUTORE che non intenda accettare le
    modifiche potrà unicamente recedere dal presente Accordo, previa
    comunicazione della propria volontà di recesso a COMMERCIO entro il
    termine di 30 (trenta) giorni dall’invio della comunicazione di cui sopra.
    Il DISTRIBUTORE avrà diritto al recesso senza costi ed oneri a suo carico,
    qualora faccia opposizione alla modifica proposta nel termine e nei modi
    indicati. Le conseguenze del recesso sono disciplinate all’art. 5 (Durata
    Accordo).
  </p>
  <h4>15.2.</h4>
  <p>
    Altre modifiche. Qualsiasi modifica al presente Accordo diversa da quella
    di cui sopra dovrà essere concordata per iscritto dalle Parti a pena di
    nullità.
  </p>
  `,
  P16: `
  <h3>16. Limitazioni di responsabilità di COMMERCIO</h3>
  <h4>16.1.</h4>
  <p>COMMERCIO non garantisce alcun guadagno.</p>
  <h4>16.2.</h4>
  <p>
    Il DISTRIBUTORE dichiara di essere consapevole che qualsiasi attività di
    promozione verrà da lui svolta in completa autonomia, a suo rischio, e
    senza riconoscimento da parte di COMMERCIO di alcun rimborso delle spese
    sostenute, né verrà fornito alcun materiale di qualsivoglia genere (es.
    brochure, gadget, ecc.).
  </p>
  <h4>16.3.</h4>
  <p>
    COMMERCIO non potrà essere ritenuto responsabile di danni, patrimoniali o
    non patrimoniali, subiti dal DISTRIBUTORE nell’esercizio delle attività di
    promozione di cui al presente Accordo o, comunque, a questo collegate
    direttamente o indirettamente.
  </p>
  <h4>16.4.</h4>
  <p>
    Il DISTRIBUTORE sarà l’unico responsabile della propria attività nei
    confronti di terzi e sarà responsabile per qualsivoglia reclamo, azione,
    ricorso o richiesta avanzata da terzi in relazione alla sua attività.
  </p>
  <h4>16.5.</h4>
  <p>
    Il DISTRIBUTORE si impegna a indennizzare e tenere comunque indenne
    COMMERCIO, i suoi dipendenti, collaboratori, fornitori e terze parti da e
    contro ogni danno, patrimoniale e non patrimoniale, perdita di guadagno,
    sanzioni, penali, spese e costi (compresi i costi di assistenza legale o
    di perizia) che dovessero insorgere in relazione all’attività di
    promozione o, comunque, a questa direttamente o indirettamente collegata.
  </p>
  `,
  P17: `
  <h3>17. Clausola risolutiva espressa</h3>
  <h4>17.1.</h4>
  <p>
    Le Parti convengono che il presente Accordo, fatti salvi i casi previsti
    dalla legge, potrà essere risolto di diritto da COMMERCIO ai sensi e per
    gli effetti dell’art. 1456 cod. civ., mediante comunicazione a mezzo
    raccomandata A/R, o pec o altro mezzo che dia prova legale di avvenuta
    ricezione nel caso in cui il DISTRIBUTORE sia inadempiente o violi una
    qualsiasi delle obbligazioni previste agli articoli che seguono:
    violazione della disciplina sull’attività di promozione (art. 8.4.)
    violazione della proprietà industriale ed intellettuale di COMMERCIO (art.
    12) violazione della disciplina sul Logo di COMMERCIO (art. 13);
    violazione degli obblighi di Confidenzialità di cui all’art. 14;
    violazione del divieto di cessione del presente Accordo.
  </p>
  <h4>17.2.</h4>
  <p>
    In tali casi la risoluzione si verificherà di diritto, quando COMMERCIO
    dichiarerà al DISTRIBUTORE che intende avvalersi della presente clausola.
    E’ fatto salvo il diritto di COMMERCIO al risarcimento del danno.
  </p>
  `,
  P18: `
  <h3>18. Informazioni precontrattuali e Disposizioni Generali</h3>
  <h4>18.1.</h4>
  <p>
    Informazioni precontrattuali. Per i contratti conclusi senza la presenza
    fisica e simultanea delle Parti (c.d. contratti a distanza), come avviene
    in caso di adesione al presente Accordo, la legge prevede che il
    Consumatore, prima di essere vincolato contrattualmente, riceva una serie
    di informazioni sull’altro contraente Professionista, sui principali
    diritti e obblighi contrattuali del Consumatore e sulle caratteristiche
    delle prestazioni contrattuali (art. 49, comma 1, e art. 51 del Cod.
    Consumo).
  </p>
  <h4>18.2.</h4>
  <p>
    Queste informazioni sono già formulate nei vari articoli dell’Accordo e
    per chiarezza e semplicità di esposizione vengono riepilogate di seguito:
  </p>
  <ul>
    <li>
      le caratteristiche principali dei COM e dei CCC (indicate nelle premesse
      e all’art. 10 ed 11) della prestazione svolta dal DISTRIBUTORE e il suo
      compenso (art. 7 Compenso ed art. 8 modalità di svolgimento
      dell’attività di promozione);
    </li>
    <li>
      l’identità di COMMERCIO, l’indirizzo geografico dove è stabilita e
      l’indirizzo elettronico [ev. anche numero di telefono] che consente al
      DISTRIBUTORE di contattare rapidamente e di comunicare efficacemente con
      COMMERCIO sono indicati in epigrafe all’art. 18.3
    </li>
    <li>
      il prezzo di acquisto e le modalità di pagamento dei beni e dei servizi
      di COMMERCIO, tra cui dei COM e quello, eventuale, dei CCC sono indicati
      nell’Accordo di Membership, mentre le condizioni di scambio (SWAP) tra
      CCC e COM sono indicate all’art. 9;
    </li>
    <li>
      le modalità e tempistiche delle transazioni degli Reward NODI sono
      indicate nell’art. 7 “Compenso, oneri e termini di pagamento”;
    </li>
    <li>
      l'informazione che il DISTRIBUTORE non beneficerà del diritto del
      recesso entro 14 giorni dalla conclusione ai sensi dell'articolo 52 Cod.
      Consumo è resa all’art. 5 (Durata dell’Accordo) al sottoparagrafo
      “Esecuzione immediata e Rinuncia al Recesso”;
    </li>
    <li>durata dell’Accordo: vedere all’art. 5 (Durata dell’Accordo);</li>
    <li>
      la funzionalità dei Contenuti digitali e dei Servizi digitali, comprese
      le misure applicabili di protezione tecnica e la compatibilità e
      interoperabilità pertinente dei Contenuti digitali e dei Servizi
      digitali, di cui la SOCIETA’ sia a conoscenza o di cui ci si può
      ragionevolmente attendere che sia venuta a conoscenza, se applicabile
      sono indicate all’art. 8 (Corso – Loghi e personalizzazione).
    </li>
  </ul>
  <h4>18.3.</h4>
  <p>
    Qualora una o più delle clausole contenute nel presente Accordo siano
    annullate o vengano dichiarate nulle o inefficaci ai sensi di legge, la
    validità delle rimanenti clausole rimarrà intatta.
  </p>
  <h4>18.4.</h4>
  <p>
    Le Parti sono contraenti indipendenti e questo Accordo non crea e non
    creerà tra le Parti rapporti di società, joint ventures o fiduciari, né
    creerà qualsiasi altra forma di associazione che possa attribuire ad una
    Parte la responsabilità per le azioni o gli inadempimenti dell’altra, né
    fornirà ad alcuna delle Parti il diritto, potere o autorità (espressa o
    implicita) di creare alcun vincolo in capo all’altra.
  </p>
  <h4>18.5.</h4>
  <p>
    Il presente Accordo è in lingua italiana. Eventuali versioni in lingue
    diverse costituiscono traduzioni di cortesia. In caso di difformità tra il
    testo in lingua italiana ed il testo in diversa lingua, la versione in
    italiano prevarrà.
  </p>
  <h4>18.6.</h4>
  <p>
    Comunicazioni. Ogni comunicazione dal DISTRIBUTORE a COMMERCIO potrà
    essere eseguita a mezzo email all’indirizzo info@commerc.io o, a mezzo pec
    all’Indirizzo commerc.io@postecert.it , o a mezzo raccomandata ar
    all’indirizzo Commercio.network spa Via Luigi dalla Via , 3/B - 36015
    Schio (VI).
  </p>
  `,
  P19: `
  <h3>19. Legge applicabile e Foro competente</h3>
  <h4>19.1.</h4>
  <p>Il presente Accordo è regolato dalla legge italiana.</p>
  <h4>19.2.</h4>
  <p>
    Qualora il DISTRIBUTORE sia un Consumatore, ai sensi dell’art. 3 comma 1
    lett. a) Codice del Consumo, sulle eventuali controversie che non vengano
    risolte tramite ADR (Alternative Dispute Resolution, o procedura di
    risoluzione alternativa delle controversie) sarà inderogabilmente
    competente il giudice del luogo di residenza o domicilio elettivo del
    DISTRIBUTORE. Qualora il DISTRIBUTORE non sia un Consumatore, le eventuali
    controversie che non vengano risolte tramite ADR saranno decise in via
    esclusiva dal Tribunale di Vicenza.
  </p>
  <h4>19.3.</h4>
  <p>
    Per le ADR che coinvolgono Consumatori la Commissione Europea fornisce una
    piattaforma per ADR e la risoluzione delle controversie online, a cui è
    possibile accedere tramite questo link:
    https://ec.europa.eu/consumers/odr/
  </p>
  `,
  TITLE_ADDITIONALS: `Termini Addizionali`,
  P_ADDITIONALS: `
  <p>
    Ai sensi dell’art. 1341 e dell’art. 1342 c.c. il DISTRIBUTORE dichiara di
    aver preso attenta visione e di approvare le seguenti previsioni
    dell’Accordo: Rinnovo automatico (art. 5), Limiti facoltà opporre
    eccezioni e decadenza (art. 7), Limitazione di garanzia (art. 10), Limiti
    di indennizzo (art. 11) Modifiche unilaterali e tacito assenso (art. 15),
    Clausola risolutiva espressa (art. 17), Foro esclusivo (art. 19)
  </p>
  `,
});

export const labels = {
  acceptCredential: 'Accept credential',
  all: 'All',
  amount: 'Amount',
  availables: 'Availables',
  back: 'Back',
  buy: 'Buy',
  buyCredits: 'Buy credits',
  buyNow: 'Buy now',
  cancel: 'Cancel',
  cardNumber: 'Card number',
  clear: 'Clear',
  code: 'Code',
  codeCvc: 'Code cvc',
  comingNext: 'Coming next',
  companyName: 'Company name',
  companyVat: 'Company vat',
  confirm: 'Confirm',
  confirmPayment: 'Confirm payment',
  consent: 'Consent',
  contentUri: 'Content URI',
  copy: 'Copy',
  country: 'Country',
  creditPrice: 'Credit price',
  creditsAvailable: 'Credits available',
  creditsSendAmount:'Amount credits to send',
  creditsSendToAddress: 'Send credits to a valid address',
  delete: 'Delete',
  deposit: 'Deposit',
  depositCredits: 'Deposit credits',
  description: 'Description',
  details: 'Details',
  didCom: 'did:com:',
  edit: 'Edit',
  email: 'Email',
  expire: 'Expire',
  expiryDate: 'Expiry date',
  fileDescription: 'File description',
  fileName: 'File name',
  fileUpload: 'File upload',
  half: 'Half',
  holderName: 'Holder name',
  inProgress: 'In progress',
  invoicingEmail: 'Invoicing email',
  legalRepresentativeCode: 'Legal representative fiscal code',
  legalRepresentativeEmail: 'Legal representative email',
  legalRepresentativeFirstName: 'Legal representative first name',
  legalRepresentativeLastName: 'Legal representative last name',
  legalRepresentativeMobile: 'Legal representative mobile',
  makeBankTransfer: 'Make bank transfer',
  max: 'Max',
  min:'Min',
  more: 'More',
  name: 'Name',
  new:'New',
  newBalance: 'New balance',
  notAvailable: 'Not available',
  notVerified: 'Not verified',
  open: 'Open',
  options: 'Options',
  paidAmount: 'Paid amount',
  paidAt: 'Paid at',
  pay: 'Pay',
  paymentId: 'Payment ID',
  processError: 'Process error',
  processId: 'Process ID',
  productQuantity: 'Product quantity',
  quantity: 'Quantity',
  received: 'Received',
  reloadApp: 'Reload app',
  removeConsent: 'Remove consent',
  save: 'Save',
  scanTheAddress: 'Scan the address',
  schemaUri: 'Schema URI',
  schemaVersion: 'Schema version',
  sdiCode: 'SDI code',
  search: 'Search',
  select: 'Select',
  send: 'Send',
  sendCredits: 'Send credits',
  sent: 'Sent',
  share: 'Share',
  signOut: 'Sign out',
  swap: 'Swap',
  swapFee: 'Swap fee',
  tokenCost: 'CCC (Commercio Cash Credit) cost',
  total: 'Total',
  totalAmount: 'Total amount',
  transactionFee: 'Transaction fee',
  transferFee: 'Transfer fee',
  unitProcessCost: 'Unit process cost',
  vat: 'Vat',
  vatAmount: 'Vat amount',
  verified: 'Verified',
  version: 'Version',
  view: 'View',
  wallet: 'Wallet',
  yes: 'Yes',
  youWillGet: 'You will get',
  youWillPay: 'You will pay',
  yourBalance: 'Your balance',
};

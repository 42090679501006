import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const AppsViewDashboard = loadView('apps/dashboard/AppsViewDashboard');

export const appsRoute = {
  path: ROUTES.PATH.APPS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.APPS,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.APPS,
      component: AppsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.APPS_DASHBOARD,
      name: ROUTES.NAME.APPS_DASHBOARD,
      component: AppsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.APPS_DASHBOARD,
      },
    },
  ],
};

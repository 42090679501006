const PATH = Object.freeze({
  ROOT: '/',
  LOGIN: '/login',
  GIFT: '/gift/:referral',
  NOT_FOUND: '/404',
  ACCOUNT: '/account',
  AGREEMENTS: '/agreements',
  AGREEMENTS_DASHBOARD: 'dasboard',
  AGREEMENTS_DETAIL: 'id/:id',
  API: '/api',
  APPS: '/apps',
  APPS_DASHBOARD: 'dashboard',
  CREDITS: '/credits',
  CREDITS_BUY: 'buy',
  CREDITS_BUY_CHOICE: 'choice',
  CREDITS_BUY_STATUS: 'status',
  CREDITS_DASHBOARD: 'dashboard',
  CREDITS_SEND: 'send',
  EARN: '/earn',
  EID: '/eid',
  MEMBERSHIP: '/membership',
  MEMBERSHIP_BENEFITS: 'extrabenefits',
  OUTPUT_CANCEL: '/output-cancel',
  OUTPUT_ERROR: '/output-error',
  OUTPUT_WAITING: '/output-waiting',
  PURCHASE: '/purchase',
  PURCHASE_AUTH_ACCOUNT: 'auth-account',
  PURCHASE_AUTH_PAYMENT: 'auth-payment',
  PURCHASE_BANK_TRANSFER: 'bank-transfer',
  PURCHASE_CHOICE: 'choice',
});

const NAME = Object.freeze({
  NOT_FOUND: 'notFound',
  ACCOUNT: 'account',
  AGREEMENTS: 'agreements',
  AGREEMENTS_DASHBOARD: 'agreementsDasboard',
  AGREEMENTS_DETAIL: 'agreementsDetail',
  API: 'api',
  APPS: 'apps',
  APPS_DASHBOARD: 'appsDashboard',
  CREDITS: 'credits',
  CREDITS_BUY: 'creditsBuy',
  CREDITS_BUY_CHOICE: 'creditsBuyChoice',
  CREDITS_BUY_STATUS: 'creditsBuyStatus',
  CREDITS_DASHBOARD: 'creditsDashboard',
  CREDITS_SEND: 'creditsSend',
  EARN: 'earn',
  EID: 'eid',
  MEMBERSHIP: 'membership',
  MEMBERSHIP_BENEFITS: 'membershipExtraBenefits',
  OUTPUT_CANCEL: 'outputCancel',
  OUTPUT_ERROR: 'outputError',
  OUTPUT_WAITING: 'outputWaiting',
  PURCHASE: 'purchase',
  PURCHASE_AUTH_ACCOUNT: 'purchaseAuthAccount',
  PURCHASE_AUTH_PAYMENT: 'purchaseAuthPayment',
  PURCHASE_BANK_TRANSFER: 'purchaseBankTransfer',
  PURCHASE_CHOICE: 'purchaseChoice',
});

const TITLE = Object.freeze({
  NOT_FOUND: 'Not Found',
  ACCOUNT: 'Account',
  AGREEMENTS: 'Agreements',
  AGREEMENTS_DASHBOARD: 'Agreements Dashboard',
  AGREEMENTS_DETAIL: 'Agreement Detail',
  API: 'Api',
  APPS: 'Apps',
  APPS_DASHBOARD: 'Apps Dashboard',
  CREDITS: 'Credits',
  CREDITS_BUY: 'Credits Buy',
  CREDITS_BUY_CHOICE: 'Credits Buy Choice',
  CREDITS_BUY_STATUS: 'Credits Buy Status',
  CREDITS_DASHBOARD: 'Credits Dashboard',
  CREDITS_SEND: 'Credits Send',
  EARN: 'Earn',
  EID: 'Eid',
  MEMBERSHIP: 'Membership',
  MEMBERSHIP_BENEFITS: 'Membership Extra Benefits',
  OUTPUT_CANCEL: 'Cancel',
  OUTPUT_ERROR: 'Error',
  OUTPUT_WAITING: 'Waiting',
  PURCHASE: 'Purchase Start',
  PURCHASE_AUTH_ACCOUNT: 'Purchase Auth Account',
  PURCHASE_AUTH_PAYMENT: 'Purchase Auth Payment',
  PURCHASE_BANK_TRANSFER: 'Purchase Bank Transfer',
  PURCHASE_CHOICE: 'Purchase Choice',
});

export default Object.freeze({
  NAME,
  PATH,
  TITLE,
});


import { CREDENTIAL } from '@/constants';

export const credentials = {
  title:{
    [CREDENTIAL.ID.ADDRESSPROOF]: 'addressproof',
    [CREDENTIAL.ID.AML]: 'aml',
    [CREDENTIAL.ID.CREDIT_CARD]: 'carta di credito',
    [CREDENTIAL.ID.EMAIL]: 'email',
    [CREDENTIAL.ID.FACECHECK]: 'facecheck',
    [CREDENTIAL.ID.IBAN]: 'iban sepa',
    [CREDENTIAL.ID.MIFID_II]: 'mifid-ii',
    [CREDENTIAL.ID.MOBILE_NUMBER]: 'numero di telefono',
    [CREDENTIAL.ID.PUBLIC_DIGITAL_IDENTITY_SYSTEM]: 'sistema di identità digitale pubblica',
  },
  description:{
    [CREDENTIAL.ID.ADDRESSPROOF]: 'Presentazione di una bolletta',
    [CREDENTIAL.ID.AML]: 'Presentazione dichiarazione DUE DILIGENCE firmata',
    [CREDENTIAL.ID.CREDIT_CARD]: 'Stripe Inc.',
    [CREDENTIAL.ID.EMAIL]: 'Messaggio e-mail per la procedura di verifica Double opt-ins',
    [CREDENTIAL.ID.FACECHECK]: 'Verifica attraverso il caricamento di documenti d\'identità e l\'acquisizione di immagini',
    [CREDENTIAL.ID.IBAN]: 'Pagamento con Stripe o Yaply',
    [CREDENTIAL.ID.MIFID_II]: 'Presentazione dichiarazione MIFID firmata',
    [CREDENTIAL.ID.MOBILE_NUMBER]: 'Messaggio Sms per la procedura di verifica Double opt-ins',
    [CREDENTIAL.ID.PUBLIC_DIGITAL_IDENTITY_SYSTEM]: 'Autenticazione con Spid (IT), eID tedesco - BSI (DE), ID elettronico CNIE (FR), ecc.',
  }
}
<template>
  <v-alert
    border="left"
    outlined
    prominent
    text
    :type="kind"
    :dismissible="dismissible"
  >
    <span class="text-body-1" v-html="message" />
  </v-alert>
</template>

<script>
export default {
  name: 'TheAlertNotice',
  props: {
    kind: {
      type: String,
      required: true,
      note: 'The alert type',
    },
    message: {
      type: String,
      required: true,
      note: 'The message to display',
    },
    dismissible: {
      type: Boolean,
      default: false,
      note: 'Enable the dismissible button',
    },
  },
};
</script>

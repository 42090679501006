<template>
  <v-app id="main">
    <v-container v-if="isLoading" fluid fill-height data-test="loading">
      <base-loading-circular />
    </v-container>
    <v-container v-else-if="!isLoading && error">
      <the-error-message :error="error" data-test="error">
        <template #actions>
          <v-layout justify-center>
            <v-btn outlined color="secondary" @click="reload">
              <span v-text="$t('labels.reloadApp')" />
            </v-btn>
          </v-layout>
        </template>
      </the-error-message>
    </v-container>
    <div v-else data-test="content">
      <router-view />
    </div>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters('account', {
      errorAccount: 'error',
      isLoadingAccount: 'isLoading',
    }),
    ...mapGetters('application', {
      errorApplication: 'error',
      isLoadingApplication: 'isLoading',
    }),
    ...mapGetters('auth', { errorAuth: 'error', isLoadingAuth: 'isLoading' }),
    isLoading() {
      return (
        this.isLoadingAccount || this.isLoadingApplication || this.isLoadingAuth
      );
    },
    error() {
      return this.errorApplication || this.errorAuth || this.errorAccount;
    },
  },
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>

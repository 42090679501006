import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestInstitutions(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/institutions',
      method: 'get',
      baseURL: APIS.YAPILY,
      headers,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestAccountAuth(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/account-auth-request',
      method: 'post',
      baseURL: APIS.YAPILY,
      headers,
      data: data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestPaymentAuth(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/payment-auth-request',
      method: 'post',
      baseURL: APIS.YAPILY,
      headers,
      data: data,
    });
  },
  /**
   *
   * @param {String} token
   * @param {Object} data
   * @returns {AxiosPromise}
   */
  requestConfirmPayment(token, data) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/confirm-payment',
      method: 'post',
      baseURL: APIS.YAPILY,
      headers,
      data: data,
    });
  },
};

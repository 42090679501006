import { eid, membership, stripe, yapily } from '@/apis';
import { ROUTES, SETTINGS } from '@/constants';
import { xLocale } from '@/locale';
import { loadStripe } from '@stripe/stripe-js';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initPurchaseDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchMemberships'),
      dispatch('account/fetchIdentity', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchMemberships({ commit }) {
    try {
      const response = await membership.requestList(xLocale, true);
      commit('setMemberships', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initBankTransfer({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchInstitutions')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchInstitutions({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await yapily.requestInstitutions(token);
      commit('setInstitutions', response.data.institutions);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * */
  async saveMetadata({ dispatch }, { contracts, data }) {
    const requests = [
      dispatch('acceptAgreements', contracts),
      dispatch('updateIdentity', data),
    ];
    await Promise.all(requests);
    await dispatch('account/fetchIdentity', null, { root: true });
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async updateIdentity({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      await eid.requestUpdate({
        locale: xLocale,
        token,
        data,
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Array.<Object>} contracts
   */
  async acceptAgreements({ commit, rootGetters }, contracts) {
    if (!contracts.length) {
      return;
    }
    const token = rootGetters['auth/token'];
    try {
      await eid.requestUpdateContracts({
        locale: xLocale,
        token,
        data: { contracts },
      });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * @param {String} membershipId
   */
  async buyMembershipWithStripe(
    { commit, dispatch },
    { contracts, data, membershipId },
  ) {
    commit('setLoading', true);
    await dispatch('saveMetadata', { contracts, data });
    await dispatch('payByStripe', membershipId);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} dispatch
   * @param {String} membershipId
   */
  async payByStripe({ dispatch }, membershipId) {
    const data = {
      cancel_url: `${SETTINGS.HOST}${ROUTES.PATH.OUTPUT_CANCEL}`,
      membership_id: membershipId,
      success_url: `${SETTINGS.HOST}${ROUTES.PATH.OUTPUT_WAITING}`,
    };
    const sessionId = await dispatch('getStripeSessionId', data);
    await dispatch('redirectToStripeCheckout', sessionId);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {Object} data
   */
  async getStripeSessionId({ commit, rootGetters }, data) {
    const token = rootGetters['auth/token'];
    try {
      const response = await stripe.requestCreateSession({
        locale: xLocale,
        token,
        data,
      });
      return response.data.session_id;
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {String} sessionId
   */
  async redirectToStripeCheckout({ commit }, sessionId) {
    if (!process.env.VUE_APP_STRIPE_PUBLIC_KEY) {
      return;
    }
    try {
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY);
      await stripe.redirectToCheckout({ sessionId: sessionId });
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {Array.<Object>} contracts
   * @param {Object} data
   * @returns {Promise<boolean>}
   */
  async saveBuyerData({ commit, dispatch }, { contracts, data }) {
    commit('setLoading', true);
    await dispatch('saveMetadata', { contracts, data });
    commit('setLoading', false);
    return true;
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} institutionId
   * @param {String} membershipId
   */
  async authorizeAccount(
    { commit, rootGetters },
    { institutionId, membershipId },
  ) {
    commit('setLoading', true);
    const token = rootGetters['auth/token'];
    const data = {
      account_redirect_url: `${SETTINGS.HOST}${ROUTES.PATH.PURCHASE}/${ROUTES.PATH.PURCHASE_AUTH_ACCOUNT}`,
      institution_id: institutionId,
      membership_id: membershipId,
      payment_redirect_url: `${SETTINGS.HOST}${ROUTES.PATH.PURCHASE}/${ROUTES.PATH.PURCHASE_AUTH_PAYMENT}`,
    };
    try {
      const response = await yapily.requestAccountAuth(token, data);
      window.location.replace(response.data.consent_url);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consent
   * @param {String} sessionId
   */
  async authorizePayment({ commit, rootGetters }, { consent, sessionId }) {
    commit('setLoading', true);
    const token = rootGetters['auth/token'];
    const data = {
      account_consent_token: consent,
      session_id: sessionId,
    };
    try {
      const response = await yapily.requestPaymentAuth(token, data);
      window.location.replace(response.data.consent_url);
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   * @param {String} consent
   * @param {String} sessionId
   */
  async confirmBankTransfer({ commit, rootGetters }, { consent, sessionId }) {
    commit('setLoading', true);
    const token = rootGetters['auth/token'];
    const data = {
      payment_consent_token: consent,
      session_id: sessionId,
    };
    try {
      const response = await yapily.requestConfirmPayment(token, data);
      if (response.data.payment_status) {
        return response.data.payment_status;
      }
    } catch (error) {
      commit('setError', error);
    } finally {
      commit('setLoading', false);
    }
  },
};

const denoms = ['uccc', 'uosmo'];

const walletHandler = {
  /**
   *
   * @param {Array.<Coin>} coins
   * @param {String} denom
   * @param {Number} decimals
   * @returns {String}
   *
   * @typedef {Object} Coin
   * @property {String} amount
   * @property {String} denom
   */
  getMillionthAmount(coins, denom, decimals = 0) {
    const coin = coins.find((coin) => coin.denom === denom);
    return coin !== undefined
      ? (parseFloat(coin.amount) / 1000000).toFixed(decimals)
      : (0).toFixed(decimals);
  },
  //TODO: to improve
  getStableTokens(coins) {
    const tokens = coins.filter((coin) => denoms.indexOf(coin.denom) >= 0);
    return tokens.length > 0 ? tokens[0] : null;
  },
};
export default walletHandler;

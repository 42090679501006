import { ekyc } from '@/apis';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initEid({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [
      dispatch('fetchCredentials'),
      dispatch('account/fetchIdentity', null, { root: true }),
    ];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {Object} rootGetters
   */
  async fetchCredentials({ commit, rootGetters }) {
    const token = rootGetters['auth/token'];
    try {
      const response = await ekyc.requestCredentials(token);
      commit('setCredentials', response.data.credentials);
    } catch (error) {
      commit('setError', error);
    }
  },
};

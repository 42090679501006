<template>
  <v-color-picker flat v-model="color" />
</template>

<script>
export default {
  name: 'BaseColorPicker',
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  emits: ['input'],
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

const ID = Object.freeze({
  ADDRESSPROOF: 'addressproof',
  AML: 'aml',
  CREDIT_CARD: 'credit_card',
  EMAIL: 'email',
  FACECHECK: 'facecheck',
  IBAN: 'iban',
  MIFID_II: 'mifid_ii',
  MOBILE_NUMBER: 'mobile_number',
  PUBLIC_DIGITAL_IDENTITY_SYSTEM: 'public_digital_identity_system',
});

const STATUS = Object.freeze({
  NOT_VALIDATED: 'not_validated',
  VALIDATED: 'validated',
  VALIDATING: 'validating',
});

export default Object.freeze({
  ID,
  STATUS,
});

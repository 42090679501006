import { COIN, SETTINGS, SUBSCRIPTION } from '@/constants';

const featureActivator = {
  /**
   *
   * @param {object} subscription
   * @param {object} wallet
   * @param {number} txsAmount
   * @returns {boolean}
   */
  hasFunds({ subscription, wallet, txsAmount = 1 } = {}) {
    const coin = wallet.coins.find((coin) => coin.denom === COIN.STABLE.DENOM);
    if (!coin) {
      return false;
    }
    const fee =
      SETTINGS.CHAIN_COST + parseInt(subscription.platform_fee.amount);
    const cost = fee * txsAmount;
    return cost <= parseInt(coin.amount);
  },
  /**
   *
   * @param {string} membershipType
   * @returns {boolean}
   */
  hasGreenMembership(membershipType) {
    return membershipType === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN;
  },
};

export default featureActivator;

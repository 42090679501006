<template>
  <base-dashboard-card :title="denom" info="0€ +0%">
    <template #content>
      <span
        class="text-h3 font-weight-bold"
        :class="amountStyle"
        v-text="amountInteger"
      />
      <span
        v-if="hasDecimals"
        class="text-decimals font-weight-bold"
        v-text="amountDecimals"
      />
      <span v-if="isOverMillion" class="ml-1 text-h3 font-weight-bold">M</span>
    </template>
  </base-dashboard-card>
</template>

<script>
export default {
  name: 'TheBalanceBox',
  props: {
    coins: {
      type: Array,
      default: () => [],
    },
    token: {
      type: String,
      required: true,
    },
  },
  computed: {
    balance() {
      return this.coins.find((coin) => coin.denom === this.token);
    },
    denom() {
      return this.balance ? this.balance.denom.substring(1) : '-';
    },
    amount() {
      return this.balance
        ? (parseInt(this.balance.amount) / 1000000).toFixed(2)
        : '0.00';
    },
    amountStyle() {
      const amount = parseFloat(this.amount);
      const color =
        amount >= 1
          ? 'primary'
          : amount < 1 && amount > 0
          ? 'warning'
          : 'error';
      return `${color}--text`;
    },
    amountParts() {
      return this.amount.split('.');
    },
    amountInteger() {
      const amount = parseInt(this.amountParts[0]);
      return amount < 1000000 ? amount : amount / 1000000;
    },
    amountDecimals() {
      return `.${this.amountParts[1]}`;
    },
    hasDecimals() {
      return parseInt(this.amount) < 1000;
    },
    isOverMillion() {
      return parseInt(this.amount) >= 1000000;
    },
  },
};
</script>

<style lang="scss" scoped>
.text-decimals {
  font-size: 0.75rem;
}
</style>

import { APIS } from '@/constants';
import axios from 'axios';
import apisHelper from './helpers/apisHelper';

export default {
  /**
   *
   * @param {String} token
   * @param {String} locale
   * @param {String} category
   * @param {Boolean} only_buyable
   * @returns {AxiosPromise}
   */
  requestList({ token, locale, category, only_buyable = true } = {}) {
    const headers = {
      Authorization: `Bearer ${token}`,
      'X-Locale': locale,
    };
    const params = apisHelper.bindValuedParams({
      category,
      only_buyable,
    });
    return axios({
      method: 'get',
      baseURL: APIS.PRODUCTS,
      headers,
      params,
    });
  },
};

import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const CreditsRouterBuy = loadView('credits/CreditsRouterBuy');
const CreditsViewBuyChoice = loadView('credits/CreditsViewBuyChoice');
const CreditsViewBuyStatus = loadView('credits/CreditsViewBuyStatus');
const CreditsViewDashboard = loadView('credits/CreditsViewDashboard');
const CreditsViewSend = loadView('credits/CreditsViewSend');

export const creditsRoute = {
  path: ROUTES.PATH.CREDITS,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.CREDITS,
  },
  children: [
    {
      path: ``,
      name: ROUTES.NAME.CREDITS,
      component: CreditsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.CREDITS_BUY,
      component: CreditsRouterBuy,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.CREDITS_BUY,
      },
      children: [
        {
          path: '',
          name: ROUTES.NAME.CREDITS_BUY,
          component: CreditsViewBuyChoice,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
          },
        },
        {
          path: ROUTES.PATH.CREDITS_BUY_CHOICE,
          name: ROUTES.NAME.CREDITS_BUY_CHOICE,
          component: CreditsViewBuyChoice,
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.CREDITS_BUY_CHOICE,
          },
        },
        {
          path: ROUTES.PATH.CREDITS_BUY_STATUS,
          name: ROUTES.NAME.CREDITS_BUY_STATUS,
          component: CreditsViewBuyStatus,
          props: (route) => ({ sessionId: route.query.session_id }),
          meta: {
            auth: AUTH.USER_ROLE.AUTHORIZED,
            tags: [SUBSCRIPTION.STATUS.ACTIVE],
            title: ROUTES.TITLE.CREDITS_BUY_STATUS,
          },
        },
      ],
    },
    {
      path: ROUTES.PATH.CREDITS_DASHBOARD,
      name: ROUTES.NAME.CREDITS_DASHBOARD,
      component: CreditsViewDashboard,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.CREDITS_DASHBOARD,
      },
    },
    {
      path: ROUTES.PATH.CREDITS_SEND,
      name: ROUTES.NAME.CREDITS_SEND,
      component: CreditsViewSend,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.CREDITS_SEND,
      },
    },
  ],
};

<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title class="primary--text">
          <span v-text="$t('titles.confirmRequest')" />
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn icon @click="dialog = false">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-3">
        <slot />
      </v-card-text>
      <v-divider class="mx-3" />
      <v-card-actions>
        <v-spacer />
        <v-btn depressed :color="buttonColor" @click="onConfirm">
          <span v-text="$t('labels.yes')" />
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js';

export default {
  name: 'BaseModalConfirmAction',
  props: {
    value: Boolean,
    buttonColor: {
      type: String,
      default: 'primary',
      note: 'The confirm button color',
    },
  },
  emits: ['confirm-action', 'input'],
  data() {
    return {
      mdiClose,
    };
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onConfirm() {
      this.$emit('confirm-action');
      this.dialog = false;
    },
  },
};
</script>

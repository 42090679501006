const CHECKOUT_DATA = [
  'company',
  'country',
  'email',
  'fiscalCode',
  'lastname',
  'membership',
  'name',
  'paymentMethod',
  'phone',
  'sdi',
  'vat',
];
const CONTRACT = ['contract_id', 'title', 'content', 'locale'];
const E_IDENTITY = ['user_id', 'type', 'wallet', 'subscription_status'];
const INFO = ['version', 'lcd_endpoint', 'ws_endpoint', 'rpc_endpoint'];
const MEMBERSHIP = [
  'cashback_currency',
  'cashback_unit',
  'created_at',
  'description',
  'display_order',
  'locale',
  'membership_id',
  'platform_fee',
  'price',
  'scope',
  'title',
  'type',
];

export default Object.freeze({
  CHECKOUT_DATA,
  CONTRACT,
  E_IDENTITY,
  INFO,
  MEMBERSHIP,
});

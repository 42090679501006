const MEMBERSHIP_TYPE = Object.freeze({
  BRONZE: 'bronze',
  GOLD: 'gold',
  GREEN: 'green',
  SILVER: 'silver',
});

const STATUS = Object.freeze({
  NONE: 'none',
  ACTIVE: 'active',
  PAST_DUE: 'past_due',
  UNPAID: 'unpaid',
  CANCELED: 'canceled',
  INCOMPLETE: 'incomplete',
  INCOMPLETE_EXPIRED: 'incomplete_expired',
  TRIALING: 'trialing',
  CHAIN_WAITING_INVITE: 'chain_waiting_invite',
  CHAIN_FAILED_INVITE: 'chain_failed_invite',
  CHAIN_WAITING_RENEWAL: 'chain_waiting_renewal',
  CHAIN_FAILED_RENEWAL: 'chain_failed_renewal',
});

export default Object.freeze({
  MEMBERSHIP_TYPE,
  STATUS,
});

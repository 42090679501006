<template>
  <v-row class="py-3 align-end">
    <v-col cols="12" md="8">
      <the-dashboard-header-commons :hasTxs="hasTxs" />
    </v-col>
    <v-col cols="12" md="4">
      <v-row class="px-2">
        <v-col cols="12" class="d-flex flex-column">
          <slot />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import TheDashboardHeaderCommons from './TheDashboardHeaderCommons';

import { mapGetters } from 'vuex';

export default {
  name: 'TheDashboardHeader',
  components: { TheDashboardHeaderCommons },
  props: {
    hasTxs: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('account', ['identity']),
  },
};
</script>

import { AUTH, ROUTES, SETTINGS } from '@/constants';

export const loginRoute = {
  path: ROUTES.PATH.LOGIN,
  meta: {
    auth: AUTH.USER_ROLE.ANY,
  },
  beforeEnter: () => {
    const location = `${AUTH.KEYCLOAK.URL}realms/${AUTH.KEYCLOAK.REALM}/protocol/openid-connect/auth?client_id=${AUTH.KEYCLOAK.CLIENT_ID}&response_type=code&scope=openid&redirect_uri=${SETTINGS.HOST}`;
    window.location.replace(location);
  },
};

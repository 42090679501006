export const msgs = {
  acceptAdditional: 'Dichiaro di aver letto e accetto specificamente i Termini Addizionali.',
  acceptError: 'Per proseguire devi accettare il contratto',
  acceptNodeAdditional: 'Dichiaro di aver letto  e accetto specificatamente i termini Addizionali ACCORDO NODO DISTRIBUTORE',
  acceptNodeTos: 'Dichiaro di aver letto l’ACCORDO NODO DISTRIBUTORE  e accetto i termini',
  acceptTos: 'Dichiaro di aver letto l\'Accordo di acquisto di beni e servizi tecnologici ("Accordo”) Termini e condizioni generali e accetto i termini.',
  addressError: 'L\'indirizzo non è corretto',
  alphaNumField: 'Questo campo accetta solo caratteri alfanumerici',
  amountError: 'Questo importo non è valido',
  apiEmptyList: 'Nessuna API trovata',
  cashBackBonus: 'cash back in crediti',
  codeLengthError: 'Min 5 e max 16 caratteri',
  configuringData: 'Attendi, stiamo configurando i tuoi dati',
  configuringError: 'Si è verificato un errore durante la configurazione, contatta l\'amministratore',
  copied: 'Copiato',
  credentialsEmptyList: 'Nessuna credenziale trovata',
  creditsBuyFooter: 'Attenzione: tutte le transazioni con crediti/token sono definitive e non possono essere annullate',
  creditsSendFooter: 'Attenzione: tutte le transazioni con crediti/token sono definitive e non possono essere annullate',
  description: 'Descrizione',
  docsLoseAssociation: 'Numero di documenti che resteranno senza associazione:',
  earnShare: 'Regala 25€ a tutte le aziende condividendo questo link e riceverai anche tu 25€ di crediti gratuiti quando acquistano un abbonamento.',
  emailField: 'Questo campo accetta solo indirizzi e-mail validi',
  errorOccurred: 'Siamo spiacenti ma si è verificato un errore',
  fiscalCodeError: 'Il codice fiscale non è corretto',
  mandatoryField: 'Questo campo è obbligatorio',
  membershipBenefits:'Il servizio consiste in una dazione variabile gratuita una Tantum di Commercio Cash Credits CCC a scopo promozionale utilizzabili al solo fine di testare i servizi.',
  membershipBenefitsIntro: 'L\'accesso a questo servizio sperimentale è concesso per un periodo temporaneo e può essere rimosso in qualsiasi momento. In questo momento, COMMERC.IO offre i seguenti Extra Benefits:',
  modalQrCodeDesc: 'Questa è la tua identità pubblica, condividila con chiunque tu voglia fare affari',
  modalQrWalletDesc: 'Questo è il tuo Wallet, condividilo con chiunque tu voglia essere pagato',
  name: 'Nome',
  noCode: 'Nessun codice',
  noMessage: 'Nessun messaggio',
  notAvailable: 'Non disponibile',
  notFound: 'Non trovato',
  operationDeleted: 'Operazione cancellata',
  pageNotFound: 'Pagina non trovata',
  paidFeature: 'Questa funzione è attiva solo con membership Bronze o superiore',
  paymentCheck: 'Attendi, stiamo verificando il pagamento e configurando i tuoi dati',
  paymentDeleted: 'Il pagamento è stato interrotto',
  paymentError: 'Processo fallito, contatta l\'amministratore',
  purchaseAuthAccount: 'Account autenticato, stai per essere indirizzato ad ottenere l\'autorizzazione al pagamento',
  purchaseAuthPayment: 'Pagamento autorizzato, per concludere l\'acquisto devi confermarlo',
  purchaseMembership: 'Usala, invita i tuoi contatti e guadagna un Reddito mensile',
  purchaseTokenCost: 'Costo di acquisto del token',
  sdiLengthError: 'Il codice SDI è una sequenza alfanumerica di 7 cifre',
  sendingConfirm: 'Confermi l\'invio?',
  sendingTokens: 'Attendi, stiamo inviando i CCC acquistati al tuo wallet',
  sendingTokensCompleted: 'Invio di CCC completato con successo',
  sendingTokensFailed: 'Invio di CCC non riuscito, stampa il sommario e contatta l\'amministratore',
  serviceTemporarilyUnavailable: "Servizio momentaneamente non disponibile",
  startTypingToFilterCountries: 'Inizia a digitare per filtrare le nazioni',
  startTypingToSearch: 'Start typing to search',
  textLengthError: 'Min 3 e max 256 caratteri',
  triggeredAnError: 'È successo qualcosa nell\'impostazione della richiesta che ha provocato un errore',
  txsEmptyList: 'Nessuna transazione trovata',
  unansweredRequest: 'La richiesta è stata inviata ma non è stata ricevuta alcuna risposta',
  vatError: 'La partita IVA non è corretta',
  vatLengthError: 'Min 5 e max 15 caratteri',
};

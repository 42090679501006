<template>
  <v-card flat :disabled="isLoading">
    <v-row align="center" class="pa-3">
      <v-col cols="12" md="1">
        <slot name="icon" />
      </v-col>
      <v-col cols="12" md="8">
        <slot name="left" />
      </v-col>
      <v-col cols="12" md="3" class="d-flex justify-end">
        <slot name="right" />
      </v-col>
      <v-col cols="12" class="py-1">
        <v-divider />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'BaseListRow',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
      note: 'Loading state',
    },
  },
};
</script>

import { jwtHandler } from '@/utils';

export default {
  error: (state) => state.error,
  isLoading: (state) => state.isLoading,
  profile: (state) => state.profile,
  attributes: (state) =>
    state.profile && state.profile.attributes ? state.profile.attributes : null,
  token: (state) => state.token,
  isAuthenticated: (state) => state.token !== '',
  tokenPayload: (state) => jwtHandler.parse(state.token),
};

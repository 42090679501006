const API_VERSION = process.env.VUE_APP_API_VERSION;
const BACKEND = process.env.VUE_APP_BACKEND;
const BASE_PATH = `${BACKEND}/${API_VERSION}`;

export default Object.freeze({
  CONTRACT: `${BASE_PATH}/contract`,
  CREDITS: `${BASE_PATH}/chain/credits`,
  EID: `${BASE_PATH}/eid`,
  EKYC: `${BASE_PATH}/eKYC`,
  INFO: `${BASE_PATH}/info`,
  MEMBERSHIP: `${BASE_PATH}/membership`,
  PROCESS: `${BASE_PATH}/process`,
  PRODUCTS: `${BASE_PATH}/products`,
  SERVICE_DISCOVERY: `${BACKEND}/v2/service-discovery`,
  STRIPE: `${BASE_PATH}/stripe`,
  YAPILY: `${BASE_PATH}/yapily`,
});

import { credentials } from './credentials.js';
import { features } from './features.js';
import { labels } from './labels.js';
import { listings } from './listings.js';
import { msgs } from './msgs.js';
import { titles } from './titles.js';

export default {
  credentials,
  features,
  labels,
  listings,
  msgs,
  titles,
};

import { APIS } from '@/constants';
import axios from 'axios';

export default {
  /**
   *
   * @param {String} token
   * @returns {AxiosPromise}
   */
  requestProcessSummary(token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    return axios({
      url: '/summary',
      method: 'get',
      baseURL: APIS.PROCESS,
      headers,
    });
  },
};

import { CREDENTIAL } from './index';

export default Object.freeze({
  STATUS: {
    DONE: 'done',
    ONGOING: 'ongoing',
    PENDING: 'pending',
    UNKNOWN: 'unknown',
  },
  TYPE: CREDENTIAL.ID,
});

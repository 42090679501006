const ROUTES = Object.freeze({
  ACCOUNT: 'account',
  TXS_DETAIL: 'transactions/detail',
});
const URL = process.env.VUE_APP_EXPLORER;

export default Object.freeze({
  ROUTES,
  URL,
});

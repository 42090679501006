import { contract } from '@/apis';
import { xLocale } from '@/locale';

export default {
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   */
  async initAgreementsDashboard({ commit, dispatch }) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContractsList')];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   */
  async fetchContractsList({ commit }) {
    try {
      const response = await contract.requestList(xLocale);
      commit('setContracts', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
  /**
   *
   * @param {Function} commit
   * @param {Function} dispatch
   * @param {String} id
   */
  async initAgreementsDetail({ commit, dispatch }, id) {
    commit('reset');
    commit('setLoading', true);
    const requests = [dispatch('fetchContractDetail', id)];
    await Promise.all(requests);
    commit('setLoading', false);
  },
  /**
   *
   * @param {Function} commit
   * @param {String} id
   */
  async fetchContractDetail({ commit }, id) {
    try {
      const response = await contract.requestDetail(id, xLocale);
      commit('setDetail', response.data);
    } catch (error) {
      commit('setError', error);
    }
  },
};

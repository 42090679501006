import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const AccountView = loadView('account/AccountView');

export const accountRoute = {
  path: ROUTES.PATH.ACCOUNT,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.ACCOUNT,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.ACCOUNT,
      component: AccountView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
  ],
};

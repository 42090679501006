import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';
import store from '@/store';

const PurchaseView = loadView('purchase/PurchaseView');
const PurchaseAuthAccount = loadView('purchase/PurchaseAuthAccount');
const PurchaseAuthPayment = loadView('purchase/PurchaseAuthPayment');
const PurchaseBankTransferView = loadView('purchase/PurchaseBankTransferView');

export const purchaseRoute = {
  path: ROUTES.PATH.PURCHASE,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.PURCHASE,
  },
  beforeEnter: (_to, _from, next) => {
    const identity = store.getters['account/identity'];
    const type = identity.subscription.membership.type;
    if (type === SUBSCRIPTION.MEMBERSHIP_TYPE.GREEN) {
      next();
    } else {
      next(ROUTES.PATH.EID);
    }
  },
  children: [
    {
      path: ``,
      name: ROUTES.NAME.PURCHASE,
      component: PurchaseView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_AUTH_ACCOUNT,
      name: ROUTES.NAME.PURCHASE_AUTH_ACCOUNT,
      component: PurchaseAuthAccount,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_AUTH_ACCOUNT,
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_AUTH_PAYMENT,
      name: ROUTES.NAME.PURCHASE_AUTH_PAYMENT,
      component: PurchaseAuthPayment,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_AUTH_PAYMENT,
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_BANK_TRANSFER,
      name: ROUTES.NAME.PURCHASE_BANK_TRANSFER,
      component: PurchaseBankTransferView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_BANK_TRANSFER,
      },
    },
    {
      path: ROUTES.PATH.PURCHASE_CHOICE,
      name: ROUTES.NAME.PURCHASE_CHOICE,
      component: PurchaseView,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.PURCHASE_CHOICE,
      },
    },
  ],
};

import dayjs from 'dayjs';
import dayOfYear from 'dayjs/plugin/dayOfYear';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(dayOfYear);
dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const dateHandler = {
  getDay(date) {
    return dayjs(date).date();
  },
  getMonth(date) {
    return dayjs(date).month();
  },
  getYear(date) {
    return dayjs(date).year();
  },
  getTodayDate(style = 'DD/MM/YYYY') {
    return dayjs().format(style);
  },
  getDifference(d1, d2, humanize = true) {
    const date1 = dayjs(d1);
    const date2 = dayjs(d2);
    const diff = date2.diff(date1);
    return humanize ? dayjs.duration(diff).humanize(true) : diff;
  },
  getFormattedDate(date, style = 'DD/MM/YYYY') {
    return dayjs(date).format(style);
  },
  getFormatByElapsedTime(date) {
    const now = dayjs();
    const d = dayjs(date);
    return now.dayOfYear() !== d.dayOfYear() || now.year() !== d.year()
      ? d.format('DD/MM/YYYY')
      : d.format('HH:mm [GMT]Z');
  },
  getDateFromUnixTimestamp(tms, style = 'DD/MM/YYYY') {
    return dayjs.unix(tms).format(style);
  },
  getUnix(date) {
    return dayjs(date).unix();
  },
  getUtcDate(date) {
    return dayjs(date)
      .utc()
      .format();
  },
};

export default dateHandler;

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import IconCommercio from '@/components/icons/IconCommercio.vue';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#38ba8c',
        background: colors.grey.lighten5,
        backgroundFooter: colors.blueGrey.lighten4,
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      commercio: {
        component: IconCommercio,
        props: {
          height: 99.4,
          width: 102.9,
        },
      },
    },
  },
});

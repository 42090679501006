import { AUTH, ONBOARDING, ROUTES, SUBSCRIPTION } from '@/constants';
import store from '@/store';

export default {
  async setupAuthorization() {
    if (!store.getters['auth/isAuthenticated']) {
      await store.dispatch('auth/initAuth');
    }
    return store.getters['auth/isAuthenticated']
      ? AUTH.USER_ROLE.AUTHORIZED
      : AUTH.USER_ROLE.NOT_AUTHORIZED;
  },
  async setupApp() {
    if (!store.getters['application/info']) {
      await store.dispatch('application/initApp');
    }
  },
  async setupAccount() {
    if (!store.getters['account/hasIdentity']) {
      await store.dispatch('account/initAccount');
    }
  },
  getHasContractsToSubscribe() {
    return store.getters['account/hasAgreementsToSubscribe'];
  },
  getOnBoardingStepUrl() {
    const onBoarding = store.getters['account/onBoarding'];
    if (
      onBoarding?.status === ONBOARDING.STATUS.DONE &&
      onBoarding?.completed_redirect_url
    ) {
      return onBoarding.completed_redirect_url;
    } else if (
      onBoarding?.status === ONBOARDING.STATUS.ONGOING ||
      onBoarding?.status === ONBOARDING.STATUS.PENDING
    ) {
      // With these states there is always at least one step
      const step = onBoarding.steps[0];
      return step.url;
    } else {
      return;
    }
  },
  getSubscriptionStatus() {
    return store.getters['account/subscriptionStatus'];
  },
  setupDefaultRoute(status) {
    switch (status) {
      case SUBSCRIPTION.STATUS.ACTIVE:
        return ROUTES.PATH.EID;
      case SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE:
        return ROUTES.PATH.OUTPUT_WAITING;
      case SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE:
        return ROUTES.PATH.OUTPUT_ERROR;
      case SUBSCRIPTION.STATUS.NONE:
        return ROUTES.PATH.OUTPUT_WAITING;
      default:
        return ROUTES.PATH.NOT_FOUND;
    }
  },
};

<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <v-card class="px-1 px-md-5" flat outlined :loading="loading">
        <v-toolbar flat>
          <v-spacer />
          <v-toolbar-title class="primary--text">
            <span v-text="title" />
          </v-toolbar-title>
          <v-spacer />
          <v-btn color="transparent" icon @click="$router.back()">
            <v-icon color="grey darken-3">{{ mdiCloseThick }}</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <slot name="main-content" />
        </v-card-text>
        <v-card-actions>
          <v-row class="text-caption">
            <v-col cols="6" class="text-center">
              <slot name="bottom-content-left" />
            </v-col>
            <v-col cols="6" class="text-center">
              <slot name="bottom-content-right" />
            </v-col>
          </v-row>
        </v-card-actions>
        <v-card-text
          v-if="footerMessage"
          class="text-caption text-center font-weight-bold"
        >
          <span v-html="footerMessage" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mdiCloseThick } from '@mdi/js';

export default {
  name: 'BaseSmallWindow',
  props: {
    title: {
      type: String,
      required: true,
      note: 'The window title',
    },
    footerMessage: {
      type: String,
      default: '',
      note: 'The footer content',
    },
    loading: {
      type: Boolean,
      default: false,
      note: 'The loading state',
    },
  },
  data() {
    return {
      mdiCloseThick,
    };
  },
};
</script>

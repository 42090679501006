<template>
  <v-tooltip top v-model="tooltip">
    <template #activator="{}">
      <span class="px-1">
        <v-btn color="primary" depressed @click="copyToClipboard">
          {{ $t('labels.copy') }}
          <v-icon right>{{ mdiContentCopy }}</v-icon>
        </v-btn>
      </span>
    </template>
    <span v-text="$t('msgs.copied')" />
  </v-tooltip>
</template>

<script>
import { mdiContentCopy } from '@mdi/js';

export default {
  name: 'BaseCopyToClipboard',
  props: {
    value: {
      required: true,
      note: 'The value to copy',
    },
  },
  data() {
    return {
      mdiContentCopy,
      tooltip: false,
    };
  },
  methods: {
    copyToClipboard() {
      if (this.tooltip) {
        this.tooltip = false;
        return;
      }
      navigator.clipboard.writeText(this.value).then(() => {
        this.tooltip = true;
        setTimeout(() => {
          if (this.tooltip) this.tooltip = false;
        }, 500);
      });
    },
  },
};
</script>

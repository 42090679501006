// import { camelCase } from 'lodash';
import { mdiApi } from '@mdi/js';
import { LISTINGS } from '@/constants';

const iconsHandler = {
  getIcon(icon) {
    return LISTINGS.APIS_ICONS[icon] || mdiApi;
  },
  // WARNING: This does not currently work
  // Rif: https://github.com/Templarian/MaterialDesign-JS/issues/21
  //
  // async getIcon(value) {
  //   const regEx = new RegExp(/[-_]{1}/, 'igm');
  //   const iconName = regEx.test(value) ? camelCase(value) : value;
  //   try {
  //     const { [iconName]: icon } = await import('@mdi/js');
  //     return icon;
  //   } catch (error) {
  //     return '';
  //   }
  // },
};

export default iconsHandler;

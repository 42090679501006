import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const MembershipViewBenefits = loadView('membership/MembershipViewBenefits');

export const membershipRoute = {
  path: ROUTES.PATH.MEMBERSHIP,
  component: () => import('@/layouts/default/DefaultLayout.vue'),
  meta: {
    auth: AUTH.USER_ROLE.AUTHORIZED,
    tags: [SUBSCRIPTION.STATUS.ACTIVE],
    title: ROUTES.TITLE.MEMBERSHIP,
  },
  children: [
    {
      path: '',
      name: ROUTES.NAME.MEMBERSHIP,
      component: MembershipViewBenefits,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
      },
    },
    {
      path: ROUTES.PATH.MEMBERSHIP_BENEFITS,
      name: ROUTES.NAME.MEMBERSHIP_BENEFITS,
      component: MembershipViewBenefits,
      meta: {
        auth: AUTH.USER_ROLE.AUTHORIZED,
        tags: [SUBSCRIPTION.STATUS.ACTIVE],
        title: ROUTES.TITLE.MEMBERSHIP_BENEFITS,
      },
    },
  ],
};

import { loadView, AUTH, ROUTES, SUBSCRIPTION } from '../index';

const NotFoundView = loadView('alerts/NotFoundView');
const OutputCancelView = loadView('alerts/OutputCancelView');
const OutputErrorView = loadView('alerts/OutputErrorView');
const OutputWaitingView = loadView('alerts/OutputWaitingView');

export const alertsRoute = [
  {
    path: ROUTES.PATH.OUTPUT_CANCEL,
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
      tags: [SUBSCRIPTION.STATUS.ACTIVE],
      title: ROUTES.TITLE.OUTPUT_CANCEL,
    },
    children: [
      {
        path: '',
        name: ROUTES.NAME.OUTPUT_CANCEL,
        component: OutputCancelView,
        meta: {
          auth: AUTH.USER_ROLE.AUTHORIZED,
          tags: [SUBSCRIPTION.STATUS.ACTIVE],
        },
      },
    ],
  },
  {
    path: ROUTES.PATH.OUTPUT_ERROR,
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
      tags: [SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE],
      title: ROUTES.TITLE.OUTPUT_ERROR,
    },
    children: [
      {
        path: '',
        name: ROUTES.NAME.OUTPUT_ERROR,
        component: OutputErrorView,
        meta: {
          auth: AUTH.USER_ROLE.AUTHORIZED,
          tags: [SUBSCRIPTION.STATUS.CHAIN_FAILED_INVITE],
        },
      },
    ],
  },
  {
    path: ROUTES.PATH.OUTPUT_WAITING,
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
      tags: [
        SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE,
        SUBSCRIPTION.STATUS.NONE,
      ],
      title: ROUTES.TITLE.OUTPUT_WAITING,
    },
    children: [
      {
        path: '',
        name: ROUTES.NAME.OUTPUT_WAITING,
        component: OutputWaitingView,
        meta: {
          auth: AUTH.USER_ROLE.AUTHORIZED,
          tags: [
            SUBSCRIPTION.STATUS.CHAIN_WAITING_INVITE,
            SUBSCRIPTION.STATUS.NONE,
          ],
        },
      },
    ],
  },
  {
    path: ROUTES.PATH.NOT_FOUND,
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    meta: {
      auth: AUTH.USER_ROLE.AUTHORIZED,
      title: ROUTES.TITLE.NOT_FOUND,
    },
    children: [
      {
        path: '',
        name: ROUTES.NAME.NOT_FOUND,
        component: NotFoundView,
        meta: {
          auth: AUTH.USER_ROLE.AUTHORIZED,
          title: ROUTES.TITLE.NOT_FOUND,
        },
      },
      {
        path: '*',
        redirect: ROUTES.PATH.NOT_FOUND,
      },
    ],
  },
];
